import NewListings from "./NewListings";
import { useState } from 'react';

function LeftPanel({ metadata, listings, isMobile }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const thumbnails = metadata.metadata.product_thumbnail ? JSON.parse(metadata.metadata.product_thumbnail) : [];

  const goToImage = (index) => {
    setCurrentImageIndex(index);
  };

  return (
    <div
      style={{
        flex: "1",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingRight: "10px",
        paddingTop: isMobile ? "10%" : "0",
        height: "100%", // Set the height to 100% of the container
      }}
    >
      <div style={{ height: "10px" }}></div>
      {thumbnails.length === 0 ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '60%', paddingTop: "20%" }}>
          <div style={{ fontSize: '1.5em' }}>No image available. :(</div>
        </div>
      ) : (
        <div style={{ width: "100%", height: "80%", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
          <div style={{ width: "100%", height: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: "10px" }}>
            <img
              src={thumbnails[currentImageIndex]}
              alt={`${metadata.title} - Image ${currentImageIndex + 1}`}
              style={{ maxWidth: "100%", maxHeight: "100%", width: "auto", height: "auto" }}
            />
          </div>
          {thumbnails.length > 1 && (
            <div style={{ display: 'flex', gap: '5px', marginTop: '10px' }}>
              {thumbnails.map((_, index) => (
                <span
                  key={index}
                  onClick={() => goToImage(index)}
                  style={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: currentImageIndex === index ? 'black' : 'gray',
                    cursor: 'pointer',
                  }}
                ></span>
              ))}
            </div>
          )}
        </div>
      )}
      {/* <div style={{ width: "100%", maxHeight: "40%", overflowY: "auto", marginTop: "50px" }}>
        <NewListings listings={listings} product_name={metadata.title} isMobile={isMobile} /> 
      </div> */}
    </div>
  );
}

export default LeftPanel;
