import React, { useState, useRef, useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import Header from "./components/Header";
import BlackboardCard from "./components/BlackboardCard";
import CriteriaChooser from "./components/CriteriaChooser";

function Blackboard({
  textFieldFocusColor = "#008000",
  buttonColor = "#944454",
  textColor = "#e8e8e8",
  backgroundColor = "#1a1a1a",
  data,
  setData,
  productType,
  criteriaList,
  websocketRef,
  listings,
  isMobile
}) {
  useEffect(() => {
    if (criteriaList.length > 0) {
      try {
        //console.log('CriteriaList changed:', criteriaList);
        // Parse the criteriaList and add all items to the blackboard
        const criteriaItems = JSON.parse(criteriaList);
        const uniqueCriteriaData = [
          ...new Map(criteriaItems.map((item) => [item.id, item])).values(),
        ];
        setData(uniqueCriteriaData);
      } catch (error) {
        console.log("Failed to parse criteriaList:", error);
      }
    }
  }, [criteriaList]);

    useEffect(() => {
    // Remove duplicate items based on their id before processing
    const uniqueData = [...new Map(data.map(item => [item.id, item])).values()];

    const idSet = new Set();
    const updatedData = uniqueData.map((item) => {
      let uniqueId = item.id;
      while (idSet.has(uniqueId)) {
        uniqueId = uniqueId + Math.random().toString(36).substr(2, 9); // Generate a new unique ID
      }
      idSet.add(uniqueId);
      return { ...item, id: uniqueId };
    });

    setData(updatedData);
  }, [data.length]); // Update only when data length changes

  const blackboardRef = useRef(null); // Ref for Blackboard
  const [clickTimeoutId, setClickTimeoutId] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Added state for window width
  const [popupOpen, setPopupOpen] = useState(null); // Now holds the id of the open popup

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Added useEffect to handle window resize

  const [prevDataLength, setPrevDataLength] = useState(data.length);

  // useEffect(() => {
  //   if (data.length > prevDataLength) {
  //     blackboardRef.current.scrollTop = blackboardRef.current.scrollHeight;
  //   }
  //   setPrevDataLength(data.length);
  // }, [data.length]);

  const removeData = (id) => {
    const updatedData = data.filter((item) => item.id !== id);
    setData(updatedData);
  };

  const moveToTop = (id, doubleClick) => {
    const index = data.findIndex((item) => item.id === id);
    if (index > 0) {
      const updatedData = Array.from(data);
      const selectedItem = updatedData.splice(index, 1)[0];
      if (doubleClick) {
        updatedData.unshift(selectedItem);
      } else {
        updatedData.splice(index - 1, 0, selectedItem);
      }
      setData(updatedData);
    }
  };

  const moveToBottom = (id, doubleClick) => {
    const index = data.findIndex((item) => item.id === id);
    if (index < data.length - 1) {
      const updatedData = Array.from(data);
      const selectedItem = updatedData.splice(index, 1)[0];
      if (doubleClick) {
        updatedData.push(selectedItem);
      } else {
        updatedData.splice(index + 1, 0, selectedItem);
      }
      setData(updatedData);
    }
  };

  return (
    <div style={{ height: "75vh", width: "100%", borderRadius: "5px" }}>
      <Header></Header>
      <div
        style={{
          padding: "1% 5%",
          width: "100%",
        }}
      >
        {productType && (
          <CriteriaChooser
            backgroundColor={backgroundColor}
            productType={productType}
            criteriaList={criteriaList}
            data={data}
            setData={setData}
            style={{ padding: "2% 5%", width: "100%" }}
          >
            {" "}
          </CriteriaChooser>
        )}
      </div>
      <div
        ref={blackboardRef}
        style={{
          padding: "2% 5%",
          height: "85%",
          overflowY: "auto",
          width: "100%",
          scrollBehavior: "smooth",
        }}
      >
        <AnimatePresence>
          {data.map((item, i) => (
            <BlackboardCard
              key={i}
              item={item}
              i={i}
              data={data}
              setData={setData}
              backgroundColor={backgroundColor}
              textColor={textColor}
              textFieldFocusColor={textFieldFocusColor}
              windowWidth={windowWidth}
              popupOpen={popupOpen}
              setPopupOpen={setPopupOpen}
              clickTimeoutId={clickTimeoutId}
              setClickTimeoutId={setClickTimeoutId}
              moveToTop={moveToTop}
              moveToBottom={moveToBottom}
              removeData={removeData}
              buttonColor={buttonColor}
              websocketRef={websocketRef}
              listings={listings}
              isMobile={isMobile}
            />
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default Blackboard;


