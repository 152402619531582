import React from "react";
import { MdChatBubbleOutline, MdPublic } from "react-icons/md";
import { FaTiktok, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import "./SidebarMobile.css";

const Topbar = ({ setProductChatToggle }) => {
  const topbarStyle = {
    backgroundColor: "#1a1a1a",
    padding: "10px 20px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const buttonStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    color: "white",
  };

  const socialMediaStyle = {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "150px",
  };

  return (
    <div style={topbarStyle}>
      <div style={{ display: "flex" }}>
        <button
          className="button"
          onClick={() => setProductChatToggle(false)}
          title="Chat"
          style={buttonStyle}
        >
          <MdChatBubbleOutline style={{ width: "24px", height: "24px" }} />
        </button>
        <button
          className="button"
          onClick={() => setProductChatToggle(true)}
          title="Discover"
          style={buttonStyle}
        >
          <MdPublic style={{ width: "24px", height: "24px" }} />
        </button>
      </div>
      <div style={socialMediaStyle}>
        <a href="https://www.tiktok.com/@kart.ai" target="_blank" rel="noopener noreferrer">
          <FaTiktok style={{ color: "white", fontSize: "24px" }} />
        </a>
        <a href="https://www.instagram.com/kart.ai_" target="_blank" rel="noopener noreferrer">
          <FaInstagram style={{ color: "white", fontSize: "24px" }} />
        </a>
        <a href="https://www.linkedin.com/company/kart-ai/" target="_blank" rel="noopener noreferrer">
          <FaLinkedin style={{ color: "white", fontSize: "24px" }} />
        </a>
        <a href="https://x.com/kart_ai_" target="_blank" rel="noopener noreferrer">
          <FaTwitter style={{ color: "white", fontSize: "24px" }} />
        </a>
      </div>
    </div>
  );
};

export default Topbar;
