import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";

function RelatedQueries({ items, textColor, handleUserMessage, product_type, userInteracted, setUserInteracted }) {
  // Push the new item to the items array
  if (items.length === 0) {
    return null;
  } else {
    return (
      <div>
        <h1
          style={{
            fontSize: "1.4em",
            fontWeight: "bold",
            margin: 0,
            padding: "5px 10px 5px 10px",
            display: "flex",
            alignItems: "center", 
          }}
        >
          <PlaylistAddIcon style={{ marginRight: "8px" }} />
          Related
        </h1>
        {items.map((item) => (
          <div
            key={item}
            style={{
              padding: "5px 10px",
              margin: "2px",
              borderRadius: "5px",
              display: "flex",
              maxWidth: "100%",
              alignItems: "flex-start",
            }}
          >
            <button
              style={{
                fontWeight: "bold",
                color: textColor,
                textAlign: "left",
                whiteSpace: "normal",
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
                padding: "8px 3px", // Increased padding for bigger button size
                margin: "-7px 0", // Negative margin to bring items closer together
                borderRadius: "3px",
                width: "100%",
                fontSize: "1em", // Increased font size for bigger text
              }}
              onClick={() => {
                handleUserMessage(item);
                setUserInteracted(false);
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = "transparent";
              }}
            >
              {item}
            </button>
          </div>
        ))}
      </div>
    );
  }
}

export default RelatedQueries;
