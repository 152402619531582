import React, { useState, useEffect } from "react";
import Switch from "@material-ui/core/Switch";
import { styled } from "@mui/material/styles";
import MaterialIcon from "material-icons-react";
import "./Sidebar.css";
import { TbLayoutSidebarLeftCollapseFilled } from "react-icons/tb";
import { TbLayoutSidebarLeftExpandFilled } from "react-icons/tb";
import { MdFace2 } from "react-icons/md";
import { MdChatBubbleOutline } from "react-icons/md"; // Changed icon for chat
import { MdStorage } from "react-icons/md";
import { MdOutlineSearch} from "react-icons/md"; // Corrected import for lightbulb icon
import { MdPublic } from "react-icons/md"; 
import { FaTiktok, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa"; // Import social media icons

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    width: 32,
    height: 32,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

const Sidebar = ({
  darkMode,
  setDarkMode,
  reviewChatToggle,
  setProductChatToggle,
  secondaryColor = "rgba(55, 55, 55, 0.9)",
}) => {
  // const [isMinimized, setIsMinimized] = useState(true);

  const sidebarStyle = {
    backgroundColor: "#1a1a1a",
    padding: "20px",
    height: "100vh",
    transition: "width 0.2s",
    display: "flex",
    flexDirection: "column",
  };

  const buttonStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  };

  const socialMediaStyle = {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    padding: "10px 0",
  };

  return (
    <div style={sidebarStyle}>
      {/* {isMinimized ? ( */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
            opacity: 1,
            transition: "opacity 0.2s",
          }}
        >
          <MaterialIcon icon="apps" size="large" color="white" />
        </div>
      {/* ) : (
        <div
          style={{
            fontSize: "2.4em",
            fontWeight: "bold",
            color: "white",
            textAlign: "center",
            opacity: 1,
            transition: "opacity 0.2s",
          }}
        >
          KART AI
        </div>
      )} */}
      <div style={{ height: "7%" }}></div>
      {/* <div style={{ marginBottom: "5%" }}>
        <button
          onClick={() => setIsMinimized(!isMinimized)}
          className="button"
          title="Expand" // {isMinimized ? "Expand" : "Collapse"}
        >
          <TbLayoutSidebarLeftExpandFilled
            style={{ width: "36px", height: "36px" }}
            title="Expand"
          />
          {isMinimized ? null : <span className="button-text" style={{ fontSize: "0.8em" }}>Minimize</span>}
        </button>
      </div> */}
      <div style={{ height: "1.5%" }}></div>
      <div style={{ marginBottom: "5%" }}>
        <button
          className="button"
          onClick={() => setProductChatToggle(false)}
          title="Chat"
          style={buttonStyle}
        >
          <MdChatBubbleOutline
            style={{ width: "36px", height: "36px" }}
            title="Chat"
          />
          {/* {isMinimized ? null : <span className="button-text" style={{ fontSize: "0.8em" }}>Chat</span>} */}
        </button>
      </div>
      <div style={{ height: "1.5%" }}></div>
      <div style={{ marginBottom: "5%" }}>
        <button
          className="button"
          onClick={() => setProductChatToggle(true)}
          title="Discover"
          style={buttonStyle}
        >
          <MdPublic
            style={{ width: "36px", height: "36px" }}
            title="Discover"
          />
          {/* {isMinimized ? null : <span className="button-text" style={{ fontSize: "0.8em" }}>Discover</span>} */}
        </button>
      </div>
      <div style={{ marginTop: "auto" }}>
        <div style={socialMediaStyle}>
          <a href="https://www.tiktok.com/@kart.ai" target="_blank" rel="noopener noreferrer">
            <FaTiktok style={{ color: "white", fontSize: "24px" }} />
          </a>
        </div>
        <div style={socialMediaStyle}>
          <a href="https://www.instagram.com/kart.ai_" target="_blank" rel="noopener noreferrer">
            <FaInstagram style={{ color: "white", fontSize: "24px" }} />
          </a>
        </div>
        <div style={socialMediaStyle}>
          <a href="https://www.linkedin.com/company/kart-ai/" target="_blank" rel="noopener noreferrer">
            <FaLinkedin style={{ color: "white", fontSize: "24px" }} />
          </a>
        </div>
        <div style={socialMediaStyle}>
          <a href="https://x.com/kart_ai_" target="_blank" rel="noopener noreferrer">
            <FaTwitter style={{ color: "white", fontSize: "24px" }} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;