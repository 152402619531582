function ProsCons({ metadata }) {
  return (
    <div style={{ textAlign: "left" }}>
      <div style={{ display: "flex", justifyContent: "center", paddingBottom: '15px' }}>
        <div style={{ width: "44%", marginRight: "5%" }}>
          <h3
            style={{
              fontSize: "1.5em",
              fontWeight: "bold",
              textAlign: "center",
              paddingBottom: "5px"
            }}
          >
            Pros
          </h3>

          {metadata.metadata.insights &&
            metadata.metadata.insights.pros.length > 0 &&
            metadata.metadata.insights.pros.map((insight, index) => (
              <a
                key={index}
                href={insight.link}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                  border: "1px solid #ddd",
                  borderRadius: "5px",
                  padding: "8px 8px",
                  textDecoration: "none",
                  color: "#333",
                  transition: "transform 0.3s, background-color 0.3s",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "rgba(240, 240, 240, 0.5)";
                  e.currentTarget.style.transform = "scale(1.05)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "";
                  e.currentTarget.style.transform = "scale(1)";
                }}
              >
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "7px",
                    border: "1px solid #ddd",
                  }}
                >
                  <img
                    src={`https://www.google.com/s2/favicons?domain=${insight.link}`}
                    alt="favicon"
                    style={{
                      width: "14px",
                      height: "14px",
                    }}
                  />
                </div>
                <span style={{ fontSize: "0.9em", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                  {insight.text}
                </span>
              </a>
            ))}
        </div>
        <div style={{ width: "44%" }}>
          <h3
            style={{
              fontSize: "1.5em",
              fontWeight: "bold",
              textAlign: "center",
              paddingBottom: "5px"
            }}
          >
            Cons
          </h3>
          {metadata.metadata.insights &&
            metadata.metadata.insights.cons.length > 0 &&
            metadata.metadata.insights.cons.map((insight, index) => (
              <a
                key={index}
                href={insight.link}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                  border: "1px solid #ddd",
                  borderRadius: "5px",
                  padding: "8px 8px",
                  textDecoration: "none",
                  color: "#333",
                  transition: "transform 0.3s, background-color 0.3s",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "rgba(240, 240, 240, 0.5)";
                  e.currentTarget.style.transform = "scale(1.05)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "";
                  e.currentTarget.style.transform = "scale(1)";
                }}
              >
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "7px",
                    border: "1px solid #ddd",
                  }}
                >
                  <img
                    src={`https://www.google.com/s2/favicons?domain=${insight.link}`}
                    alt="favicon"
                    style={{
                      width: "14px",
                      height: "14px",
                    }}
                  />
                </div>
                <span style={{ fontSize: "0.9em", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                  {insight.text}
                </span>
              </a>
            ))}
        </div>
      </div>
    </div>
  );
}

export default ProsCons;