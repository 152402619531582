function Header() {
  return (
    <div
      style={{
        padding: "15px",
        margin: "5px auto",
        display: "block",
        maxWidth: "90%",
        marginBottom: "10px",
      }}
    >
      {/* <h1 style={{ fontSize: "1.6em", fontWeight: "bold", margin: 0 }}>
        Criteria Blackboard
      </h1> */}
      {/* <p
        style={{
          whiteSpace: "pre-wrap",
          lineHeight: "1.5",
          fontStyle: "italic",
          fontSize: "0.9em"
        }}
      >
        Here is where our criteria suggestions will show up.
        You can personalize and filter for products as we chat!
      </p> */}
    </div>
  );
}

export default Header;
