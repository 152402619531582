import React from 'react';

function Blog({ isMobile }) {
    const cardStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
        margin: "10px",
        border: "2px solid black",
        borderRadius: "10px",
        textDecoration: "none",
        color: "#000",
        backgroundColor: "#fff",
        transition: "transform 0.3s, background-color 0.3s",
        height: "650px",
        position: "relative",
    };

    const handleMouseEnter = (e) => {
        e.currentTarget.style.backgroundColor = "rgba(240, 240, 240, 0.5)";
        e.currentTarget.style.transform = "scale(1.01)";
    };

    const handleMouseLeave = (e) => {
        e.currentTarget.style.backgroundColor = "#fff";
        e.currentTarget.style.transform = "scale(1)";
    };

    const blogPosts = [
        {
            title: "kart.ai @ lichess.org",
            url: "https://twitch.tv/penguingm1",
            text: `A mysterious account by the name of <b> Kart AI </b> has reached the highest rating! <br> <br>
                    That beats the previous records set by Magnus Carlsen (3379), Alireza Firouzja (3383), Andrew Tang (3319)... <br> <br>
                    Who could this be? Tune into a stream on <b> August 17, 3:00 PM ET. </b>`,
            image: "kart_chess.png",
            date: "2024-08-15"
        },
        {
            title: "Best equipment for your WFH setup",
            url: "https://kart.ai/#pwasd7aj6ar",
            text: `Over the last few months, us co-founders have moved from <b> Stanford </b> to <b> Seattle </b> and back to <b> San Francisco </b>...
                    and if there's anything we've learned, it's that a good home office space goes a long way. <br> <br>
                    Here are a few products -- monitors, webcams, and headpones -- you may consider.`,
            image: "https://a.storyblok.com/f/99519/1100x619/f8cc46dd1d/the-biggest-challenges-of-working-from-home-3.png",
            date: "2024-08-14"
        },
        {
            title: "New Feature: Threads",
            url: "https://kart.ai/#3fg3vi3qimd",
            text: `We are happy to announce <b> threads </b> on kart.ai! <br> <br>
                    Every time you start a conversation on kart, your conversation has a unique hash. 
                    This makes it easier to share your research with friends and family. <br> <br>
                    Click on this post for an example on <b> gaming mice</b>. Happy shopping!`,
            image: "https://media.istockphoto.com/id/1437347979/vector/detective-office-interior-element-wall-board-wits-and-deduction-system-crime-and-criminal.jpg?s=612x612&w=0&k=20&c=rDcyJKE_2OzW-O3LOONNGUD9v0JE_riOYiqsMF8IJiA=",
            date: "2023-08-12"
        },
        // Add more blog posts as needed
    ];

    const renderCard = ({ title, url, text, image, date }) => (
        <a
            key={title}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            style={cardStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <h2 style={{fontSize: "1.4em", fontWeight: "bold", position: isMobile ? "static" : "absolute", top: isMobile ? "auto" : "10px", marginBottom: isMobile ? "15px" : "0" }}>{title}</h2>
            <img src={image} alt={title} style={{ width: "100%", height: "350px", objectFit: "cover", borderRadius: "10px 10px 0 0" }} />
            <p style={{marginTop: "20px"}} dangerouslySetInnerHTML={{ __html: text.replace(/<b>(.*?)<\/b>/g, '<strong>$1</strong>') }}></p>
            <span style={{ position: isMobile ? "static" : "absolute", bottom: isMobile ? "auto" : "10px", right: isMobile ? "auto" : "10px", fontSize: "0.8em"}}>{date}</span>
        </a>
    );

    return (
        <div style={{ width: "100%", height: isMobile? '90vh': '100vh', overflow: 'auto', paddingRight: '5%', paddingLeft: '5%' }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "20px", marginTop: "3%" }}>
                <h1 style={{ fontSize: "2.5em", fontWeight: "bold", margin: "0" }}><u>kart.ai</u> blog</h1>
                <p style={{ fontSize: "1.1em", color: "#555", marginTop: "10px", textAlign: "center" }}>Stay updated with the latest news and the most popular threads!</p>
            </div>
            <div style={{ display: "flex", justifyContent: "center", padding: "20px", width: "100%", overflowY: "auto" }}>
                <div style={{ display: "grid", gridTemplateColumns: isMobile ? "repeat(auto-fit, minmax(100%, 1fr))" : "repeat(auto-fit, minmax(500px, 1fr))", gap: "20px", width: "100%" }}>
                    {blogPosts.map(renderCard)}
                </div>
            </div>
        </div>
    );
}

export default Blog;