import { useState, useMemo } from 'react';

const INITIAL_VISIBLE_ROWS = 1;

function NewReviews({ reviews, bulletPoints, isMobile }) {
  const [filter, setFilter] = useState({ sentiment: 'all' });
  const [visibleRows, setVisibleRows] = useState({ positive: INITIAL_VISIBLE_ROWS, neutral: INITIAL_VISIBLE_ROWS, negative: INITIAL_VISIBLE_ROWS });

  const sentimentCounts = reviews.reduce((acc, review) => {
    if (review.type === 'reddit') {
      if (review.sentiment === 'neutral') {
        acc[review.sentiment] = (acc[review.sentiment] || 0) + 0.5;
      } else {
        acc[review.sentiment] = (acc[review.sentiment] || 0) + 1;
      }
    }
    return acc;
  }, {});

  const totalSentimentCount = Object.values(sentimentCounts).reduce((sum, count) => sum + count, 0);

  const sortedAndFilteredReviews = useMemo(() => {
    const filtered = reviews.filter((review) => {
      const sentimentMatches = filter.sentiment === 'all' || review.sentiment === filter.sentiment;
      return sentimentMatches && review.type === 'reddit';
    });
    return filtered;
  }, [reviews, filter]);

  const renderReviews = (sentiment) => {
    const reviewsToShow = sortedAndFilteredReviews.filter(review => review.sentiment === sentiment);
    const visibleReviews = reviewsToShow.slice(0, visibleRows[sentiment] * 2);

    return (
      <div>
        <div style={{ 
          display: 'grid', 
          gridTemplateColumns: 'repeat(2, minmax(330px, 1fr))',
          gridTemplateRows: 'repeat(2, auto)',
          gap: '10px',
          justifyContent: 'center',
          alignItems: 'center',
          overflowX: 'hidden',
          overflowY: 'hidden',
          width: '100%',
          padding: "10px"
        }}>
        {visibleReviews.map((review) => (
          <div
            style={{
              border: "1px solid #000",
              padding: "10px",
              borderRadius: "5px",
              height: "175px",
              position: "relative",
              cursor: review.url ? "pointer" : "default",
              overflowX: "auto",
              overflowY: "auto",
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              transition: 'transform 0.3s', // Added transition for smooth scaling
            }}
            key={review.id}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = review.sentiment === 'positive' ? "rgba(125, 238, 125, 0.2)" : review.sentiment === 'negative' ? "rgba(255, 100, 100, 0.2)" : "rgba(240, 240, 240, 0.8)";
              e.currentTarget.style.transform = 'scale(1.01)'; // Added scaling on hover
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "";
              e.currentTarget.style.transform = 'scale(1)'; // Reset scaling on mouse leave
            }}
            onClick={review.url ? (e) => { e.stopPropagation(); window.open(review.url, "_blank"); } : undefined}
          >
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h3 style={{ fontWeight: "bold", fontSize: "0.95em", flex: 1 }}>{review.title}</h3>
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px', paddingLeft: '5px', marginLeft: '5px' }}>
                  <img 
                    src="https://www.redditstatic.com/desktop2x/img/favicon/favicon-32x32.png" 
                    alt="Review" 
                    style={{ 
                      width: "20px", 
                      height: "20px" 
                    }} 
                  />
                </div>
              </div>
              <p style={{ fontSize: "0.9em", marginTop: "15px" }}><strong>Summary:</strong> {review.summary}</p>
            </div>
              
            <div>
              {!review.url ? (
                <p style={{ marginTop: "5px", fontSize: "0.85em" }}>
                  {review.text.split('.').slice(0, 3).join('.').replace("Read more", "")}
                  {(review.text.length - review.text.split('.').slice(0, 2).join('.').length) > 2 && review.text.split('.').slice(0, 2).join('.').length > 50 ? "..." : ""}
                </p>
              ) : (
                <p style={{ marginTop: "5px" }}></p>
              )}
            </div>
          </div>
        ))}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '10px' }}>
            {visibleRows[sentiment] > INITIAL_VISIBLE_ROWS && (
              <button 
                onClick={() => setVisibleRows(prev => ({ ...prev, [sentiment]: INITIAL_VISIBLE_ROWS}))} 
                style={{ 
                  padding: '10px 20px', 
                  cursor: 'pointer', 
                  color: 'black', 
                  border: 'none', 
                  borderRadius: '5px', 
                  fontSize: '1em', 
                  fontWeight: 'bold',
                  transition: 'transform 0.3s',
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = 'scale(1.05)';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = 'scale(1)';
                }}
              >
                <span className="material-icons" style={{ verticalAlign: 'middle' }}>expand_less</span> Show less... <span className="material-icons" style={{ verticalAlign: 'middle' }}>expand_less</span>
              </button>
            )}
            {reviewsToShow.length > visibleReviews.length && (
              <button 
                onClick={() => setVisibleRows(prev => ({ ...prev, [sentiment]: prev[sentiment] + 1 }))} 
                style={{ 
                  padding: '10px 20px', 
                  cursor: 'pointer', 
                  color: 'black', 
                  border: 'none', 
                  borderRadius: '5px', 
                  fontSize: '1em', 
                  fontWeight: 'bold',
                  transition: 'transform 0.3s',
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = 'scale(1.05)';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = 'scale(1)';
                }}
              >
                <span className="material-icons" style={{ verticalAlign: 'middle' }}>expand_more</span> Show more... ({reviewsToShow.length - visibleReviews.length} hidden) <span className="material-icons" style={{ verticalAlign: 'middle' }}>expand_more</span>
              </button>
            )}
          </div>
        </div>
    );
  };

  if (isMobile) {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        {bulletPoints.positive_summary && bulletPoints.positive_summary.length > 0 && (
          <div style={{ marginBottom: '20px' }}>
            <strong style={{ fontSize: '1.3em' }}>Customers like this product for...</strong>
            <ul style={{ listStyleType: 'disc', paddingLeft: '20px', marginTop: '10px' }}>
              {bulletPoints.positive_summary.map((point, index) => (
                <li key={index} style={{ marginBottom: '5px' }}>{point}</li>
              ))}
            </ul>
          </div>
        )}
        {bulletPoints.negative_summary && bulletPoints.negative_summary.length > 0 && (
          <div style={{ marginBottom: '20px' }}>
            <strong style={{ fontSize: '1.3em' }}>Customers do not like...</strong>
            <ul style={{ listStyleType: 'disc', paddingLeft: '20px', marginTop: '10px' }}>
              {bulletPoints.negative_summary.map((point, index) => (
                <li key={index} style={{ marginBottom: '5px' }}>{point}</li>
              ))}
            </ul>
          </div>
        )}
        {bulletPoints.neutral_summary && bulletPoints.neutral_summary.length > 0 && (
          <div style={{ marginBottom: '20px' }}>
            <strong style={{ fontSize: '1.3em' }}>Customers feel neutral about...</strong>
            <ul style={{ listStyleType: 'disc', paddingLeft: '20px', marginTop: '10px' }}>
              {bulletPoints.neutral_summary.map((point, index) => (
                <li key={index} style={{ marginBottom: '5px' }}>{point}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {/* Sentiment Proportion Bar */}
      <div style={{ marginTop: '20px', marginBottom: '20px', textAlign: 'center', flex: '0 0 auto' }}>
        <p style={{ fontSize: '1.2em', fontWeight: 'normal', color: 'black' }}>
          We've found a total of <span style={{ fontWeight: 'bold' }}>{reviews.filter(review => review.type === 'reddit').length}</span> reviews and they were <span style={{ fontWeight: 'bold' }}>{(sentimentCounts.positive / reviews.filter(review => review.type === 'reddit').length * 100).toFixed(2)}%</span> positive.
        </p>
      </div>
      {/* End of Sentiment Proportion Bar */}

      <div style={{ overflowY: "auto", flex: '1 1 auto' }}>
        {reviews.filter(review => review.sentiment === 'positive' && review.type === 'reddit').length > 0 && (
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
            <div style={{ flex: '0 0 25%', minWidth: '350px', padding: '10px', alignSelf: 'flex-start' }}>
              {bulletPoints.positive_summary && bulletPoints.positive_summary.length > 0 && (
                <strong style={{ fontSize: '1.3em' }}>
                  {sentimentCounts.positive} {sentimentCounts.positive === 1 ? 'customer' : 'customers'} like this product for...
                </strong>
              )}
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px', marginTop: '10px' }}>
                {bulletPoints.positive_summary && bulletPoints.positive_summary.map((point, index) => (
                  <li key={index} style={{ marginBottom: '5px' }}>{point}</li>
                ))}
              </ul>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              {renderReviews('positive')}
            </div>
          </div>
        )}

        {reviews.filter(review => review.sentiment === 'negative' && review.type === 'reddit').length > 0 && (
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
            <div style={{ flex: '0 0 25%', minWidth: '350px', padding: '10px', alignSelf: 'flex-start' }}>
              {bulletPoints.negative_summary && bulletPoints.negative_summary.length > 0 && (
                <strong style={{ fontSize: '1.3em' }}>
                  {sentimentCounts.negative} {sentimentCounts.negative === 1 ? 'customer does' : 'customers do'} not like...
                </strong>
              )}
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px', marginTop: '10px' }}>
                {bulletPoints.negative_summary && bulletPoints.negative_summary.map((point, index) => (
                  <li key={index} style={{ marginBottom: '5px' }}>{point}</li>
                ))}
              </ul>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              {renderReviews('negative')}
            </div>
          </div>
        )}

        {reviews.filter(review => review.sentiment === 'neutral' && review.type === 'reddit').length > 0 && (
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
            <div style={{ flex: '0 0 25%', minWidth: '350px', padding: '10px', alignSelf: 'flex-start' }}>
              {bulletPoints.neutral_summary && bulletPoints.neutral_summary.length > 0 && (
                <strong style={{ fontSize: '1.3em' }}>
                  {sentimentCounts.neutral} {sentimentCounts.neutral === 1 ? 'customer thinks' : 'customers think'}...
                </strong>
              )}
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px', marginTop: '10px' }}>
                {bulletPoints.neutral_summary && bulletPoints.neutral_summary.map((point, index) => (
                  <li key={index} style={{ marginBottom: '5px' }}>{point}</li>
                ))}
              </ul>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              {renderReviews('neutral')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default NewReviews;
