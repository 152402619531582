import React from 'react';
import { motion } from 'framer-motion';

const CriteriaChooser = ({ backgroundColor, productType, criteriaList, data, setData }) => {
  return (
    <motion.div
      style={{
        padding: "12px 12px",
        margin: "7px",
        borderRadius: "2px",
        border: `2px dashed ${
          backgroundColor === "#1a1a1a" ? "#e8e8e8" : "#5c5555"
        }`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "100%",
        marginBottom: "5px",
        height: "140px",
        position: "relative",
        cursor: "pointer",
        backgroundColor:
          backgroundColor === "#1a1a1a"
            ? "rgba(111, 111, 111, 0.1)"
            : "rgba(111, 111, 111, 0.05)",
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.2 } }}
      layout
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <p style={{ fontSize: "1em" }}>
          Filter {" "}
          <strong>
            {productType.charAt(0).toUpperCase() + productType.slice(1)}
          </strong>
          {" "} by:
        </p>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              marginTop: "8px",
            }}
          >
            <select
              onChange={() =>
                (document.getElementById("error-message").innerText = "")
              }
              style={{
                width: "180px",
                padding: "8px",
                borderRadius: "5px",
                border: "1px solid #000",
                fontSize: "0.9em",
              }}
            >
              <option value="">Choose...</option>
              {JSON.parse(criteriaList).map((criterion, index) => (
                <option
                  key={index}
                  value={JSON.stringify(criterion)}
                  style={{ fontSize: "0.9em" }}
                >
                  {criterion.title}
                </option>
              ))}
            </select>
            <button
              onClick={() => {
                let selectElement = document.querySelector("select");
                if (selectElement.value === "") {
                  document.getElementById("error-message").innerText =
                    "Choose an option";
                } else {
                  let selectedOption = JSON.parse(
                    selectElement.options[selectElement.selectedIndex].value
                  );
                  if (selectedOption !== "") {
                    if (!data.some((item) => item.id === selectedOption.id)) {
                      document.getElementById("error-message").innerText = "";
                      setData([...data, selectedOption]);
                    } else {
                      document.getElementById("error-message").innerText =
                        "Criterion already present in blackboard";
                    }
                  }
                }
              }}
              style={{
                width: "40px",
                height: "40px",
                backgroundColor: "grey",
                marginLeft: "5%",
                color: "white",
                padding: "5px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "0.9em",
              }}
            >
              <i class="material-icons" style={{ fontSize: "25px" }}>
                add
              </i>
            </button>
          </div>
        </div>
        <div style={{ height: "5px" }}></div>
        <p id="error-message" style={{ color: "red" }}></p>
      </div>
    </motion.div>
  );
};

export default CriteriaChooser;
