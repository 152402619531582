import { useState, useRef, useEffect } from "react";
import "./App.css";
import "typeface-roboto";
import React from "react";
import ChatContainer from "./ChatContainer/ChatContainer";
import Sidebar from "./Sidebar/Sidebar";
import Blackboard from "./Blackboard/Blackboard";
import ProductDatabase from "./ProductDatabase/ProductDatabase";
import FeedbackForm from "./Feedback/FeedbackForm";
import FeedbackButton from "./Feedback/FeedbackButton";
import Blog from "./Blog/Blog";
import Topbar from "./Sidebar/SidebarMobile";

import {
  SocketReceiveChat,
  SocketReceiveRecommendations,
  SocketReceiveCriteria,
  // openCriteria,
  SocketReceiveFollowups,
  SocketSendStop,
} from "./ChatContainer/socket_utils";

const App = () => {
  const [darkMode, setDarkMode] = useState(false); // Added darkMode state

  const defaultBackground = "#ffffff"; // even lighter blue closer to white
  const darkmodeBackground = "#1a1a1a";
  const defaultTextColor = "#302d2d"; //#5c5555";
  const darkmodeTextColor = "#e8e8e8";

  const [reviewChatToggle, setProductChatToggle] = useState(false); // by default, chat is shown, so it is false.
  const [listings, setListings] = useState({});
  const [reviewDatabaseListings, setReviewDatabaseListings] = useState({});
  const [productDatabaseListings, setProductDatabaseListings] = useState({});
  const [sources, setSources] = useState([]);
  const [data, setData] = useState([]);
  const [productType, setProductType] = useState(null);
  const [criteriaList, setCriteriaList] = useState([]);
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [relatedQueries, setRelatedQueries] = useState([]);
  const [sourceSummaries, setSourceSummaries] = useState({});
  const [feedbackFormOpen, setFeedbackFormOpen] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [userInteracted, setUserInteracted] = useState(false);

  const [chatMessages, setChatMessages] = useState([]);
  const websocketRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [hashUpdated, setHashUpdated] = useState(false); // State to track if hash has been updated
  const [waitingForChatHistory, setWaitingForChatHistory] = useState(false);
  const [scrollDirection, setScrollDirection] = useState("up"); // State to track scroll direction

  useEffect(() => {
    if (!window.location.hash) {
      setChatMessages([
        {
          message: "Hello, from your new shopping assistant for electronics.",
          sender: "assistant",
          isHeader: true, // Added isHeader property to mark the first message as a header
        },
      ]);
    } else {
      setChatMessages([]);
      setWaitingForChatHistory(true);
      setHashUpdated(true);
    }
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (chatMessages.length > 1 && !hashUpdated) {
      updateURLWithHash();
      setHashUpdated(true);
    }
  }, [chatMessages, hashUpdated]);

  const backend_host =
    window.location.hostname === "localhost"
      ? "http://localhost:8000"
      : "https://omni-backend.com";

  useEffect(() => {
    const websocketHost =
      window.location.hostname === "localhost"
        ? "ws://localhost:8000/ws/chat/"
        : "wss://omni-backend.com/ws/chat/";
    websocketRef.current = new WebSocket(websocketHost);

    websocketRef.current.onopen = () => {
      if (window.location.hash) {
        setUserInteracted(true);
        websocketRef.current.send(
          JSON.stringify({
            type: "chat_history_requested",
            chat_hash: window.location.hash.substring(1),
          })
        );
      }
    };

    websocketRef.current.onmessage = (event) => {
      const response_json = JSON.parse(event.data);
      if (response_json.type === "chat") {
        SocketReceiveChat(
          setChatMessages,
          response_json,
          setWaitingForResponse,
          websocketRef
        );

        if (response_json.message === null) {
          websocketRef.current.send(
            JSON.stringify({
              type: "get_followups",
            })
          );
        }
      } else if (response_json.type === "chat_history") {
        setChatMessages((prevMessages) => {
          const newMessages = response_json.messages
            .map((msg) => {
              if (msg.role) {
                return {
                  sender: msg.role,
                  message: msg.content,
                };
              } else {
                const response_json = JSON.parse(msg).response;
                setSources(
                  Array.from(
                    new Set(
                      response_json.map((item) =>
                        JSON.stringify({
                          text: item.fields["source_name"],
                          link: item.fields["source"],
                        })
                      )
                    )
                  ).map((item) => JSON.parse(item))
                );

                const productNamesJson = response_json.map((item) =>
                  JSON.stringify(item.fields)
                );
                return {
                  message: productNamesJson,
                  sender: "recommender",
                  topic: JSON.parse(msg).topic,
                };
              }
            })
            .filter(Boolean);

          return [
            ...[
              {
                message: "Hello, from your new shopping assistant.",
                sender: "assistant",
                isHeader: true, // Added isHeader property to mark the first message as a header
              },
            ],
            ...newMessages,
          ];
        });

        setRelatedQueries(response_json.related_queries);
        setWaitingForChatHistory(false);
      } else if (response_json.type === "criteria") {
        SocketReceiveCriteria(
          setChatMessages,
          response_json,
          setWaitingForResponse,
          setData,
          websocketRef
        );

        if (response_json.message === null) {
          websocketRef.current.send(
            JSON.stringify({
              type: "get_followups",
            })
          );
        }
      } else if (response_json.type === "criteria_json") {
        setData((prevData) => {
          const newData = [...(prevData || []), ...response_json.message];
          return Array.from(new Set(newData.map(JSON.stringify))).map(
            JSON.parse
          );
        });
      } else if (response_json.type === "get_source_chunks") {
        SocketReceiveRecommendations(
          setChatMessages,
          setSources,
          setData,
          setWaitingForResponse,
          websocketRef,
          response_json.response,
          response_json.topic ? response_json.topic : null
        );

        if (response_json.message === null) {
          websocketRef.current.send(
            JSON.stringify({
              type: "get_followups",
            })
          );
        }
      } else if (response_json.type === "followups") {
        SocketReceiveFollowups(
          setRelatedQueries,
          setWaitingForResponse,
          response_json.questions
        );
      } else if (response_json.type === "price_listings") {
        // update messages
        setChatMessages((prevMessages) => [
          ...prevMessages,
          { message: response_json.message, sender: "price" },
        ]);
      } else if (response_json.type === "product_type") {
        if (
          typeof response_json.product === "string" &&
          response_json.product.trim().length > 0
        ) {
          setProductType(response_json.product);
          setCriteriaList(response_json.criteria_jsons);
        }
      } else if (response_json.type === "listings") {
        setListings((prevListings) => {
          const { [response_json.product_name]: _, ...rest } = prevListings;
          return {
            ...rest,
            [response_json.product_name]: response_json.listings,
          };
        });
      } else if (response_json.type === "source_summary") {
        setSourceSummaries((prevSourceSummaries) => {
          const product_id = response_json.product_id;
          const message = response_json.message;
          return {
            ...prevSourceSummaries,
            [product_id]: prevSourceSummaries[product_id]
              ? prevSourceSummaries[product_id] + message
              : message,
          };
        });
      } else if (response_json.type == "reviewdatabase_json") {
        setReviewDatabaseListings(response_json);
      } else if (response_json.type == "productdatabase_json") {
        setProductDatabaseListings(response_json);
      } else if (response_json.type == "stop_confirmation") {
        setChatMessages([
          {
            message: "Hello, from your new shopping assistant.",
            sender: "assistant",
            isHeader: true, // Added isHeader property to mark the first message as a header
          },
        ]);
        setData([]);
        setSources([]);
        setProductType(null);
        setCriteriaList([]);
        setWaitingForResponse(false);
        setRelatedQueries([]);
        setSourceSummaries({});
        setHashUpdated(false); // Reset hashUpdated state
        window.location.hash = "";
      }
    };

    websocketRef.current.onerror = (event) => {
      console.error("WebSocket error:", event);
    };

    return () => {
      websocketRef.current.close();
    };
  }, [setData, setSources, setSourceSummaries]);

  useEffect(() => {
    //console.log('Sources updated:', sources);
  }, [sources]);

  const handleClearChat = () => {
    SocketSendStop(websocketRef);
  };

  // we send chats from before. however, old information may not work
  const updateURLWithHash = () => {
    const hash = Math.random().toString(36).substring(2, 18);
    window.location.hash = hash;
    if (
      websocketRef.current &&
      websocketRef.current.readyState === WebSocket.OPEN
    ) {
      websocketRef.current.send(
        JSON.stringify({
          type: "hash_update",
          chat_hash: hash,
        })
      );
    }
  };

  useEffect(() => {
    let lastScrollY = window.scrollY;
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setScrollDirection("down");
      } else {
        setScrollDirection("up");
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        overflow: "hidden",
        background: "#ffffff",
        color: darkMode ? darkmodeTextColor : defaultTextColor,
        minHeight: "100vh",
        flexDirection: isMobile ? "column" : "row",
        position: "relative",
      }}
    >
      {isMobile ? (
        <>
          <div style={{ height: scrollDirection === "down" ? "0" : "7%", transition: "height 0.3s" }}>
            <Topbar setProductChatToggle={setProductChatToggle} />
          </div>
          <div style={{ height: "93%", display: "flex", flexDirection: "column" }}>
            {feedbackFormOpen && (
              <FeedbackForm
                setFeedbackFormOpen={setFeedbackFormOpen}
                feedbackSubmitted={feedbackSubmitted}
                setFeedbackSubmitted={setFeedbackSubmitted}
                setSelectedFeedback={setSelectedFeedback}
                backend_host={backend_host}
                selectedFeedback={selectedFeedback}
              />
            )}
            <div style={{ display: "flex", width: "100%" }}>
              <div style={{ width: "100%" }}>
                {!reviewChatToggle ? (
                  <ChatContainer
                    textColor={darkMode ? darkmodeTextColor : defaultTextColor}
                    backgroundColor={darkMode ? darkmodeBackground : defaultBackground}
                    textboxBackgroundColor={darkMode ? "rgba(90,90,90,1)" : "#e4e4e4"}
                    shadowColor={darkMode ? defaultTextColor : defaultBackground}
                    inputBackgroundColor={
                      darkMode ? "rgba(90,90,90,0.5)" : "rgba(225,225,225,0.5)"
                    }
                    data={data}
                    setData={setData}
                    sources={sources}
                    productType={productType}
                    criteriaList={criteriaList}
                    setCriteriaList={setCriteriaList}
                    chatMessages={chatMessages}
                    setChatMessages={setChatMessages}
                    websocketRef={websocketRef}
                    relatedQueries={relatedQueries}
                    setRelatedQueries={setRelatedQueries}
                    waitingForResponse={waitingForResponse}
                    setWaitingForResponse={setWaitingForResponse}
                    listings={listings}
                    handleClearChat={handleClearChat}
                    isMobile={isMobile}
                    userInteracted={userInteracted}
                    setUserInteracted={setUserInteracted}
                    waitingForChatHistory={waitingForChatHistory}
                  />
                ) : (
                  <Blog isMobile={isMobile} />
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <FeedbackButton setFeedbackFormOpen={setFeedbackFormOpen} />
          {feedbackFormOpen && (
            <FeedbackForm
              setFeedbackFormOpen={setFeedbackFormOpen}
              feedbackSubmitted={feedbackSubmitted}
              setFeedbackSubmitted={setFeedbackSubmitted}
              setSelectedFeedback={setSelectedFeedback}
              backend_host={backend_host}
              selectedFeedback={selectedFeedback}
            />
          )}
          <div style={{ display: "flex", width: "100%" }}>
            <div style={{ width: "90px", maxWidth: "90px" }}>
              <Sidebar
                darkMode={darkMode}
                setDarkMode={setDarkMode}
                reviewChatToggle={reviewChatToggle}
                setProductChatToggle={setProductChatToggle}
              />
            </div>
            <div style={{ display: "flex", width: "calc(100% - 90px)" }}>
              <div style={{ width: (chatMessages.length > 1 && !reviewChatToggle) ? "77%" : "100%" }}>
                {!reviewChatToggle ? (
                  <ChatContainer
                    textColor={darkMode ? darkmodeTextColor : defaultTextColor}
                    backgroundColor={darkMode ? darkmodeBackground : defaultBackground}
                    textboxBackgroundColor={darkMode ? "rgba(90,90,90,1)" : "#e4e4e4"}
                    shadowColor={darkMode ? defaultTextColor : defaultBackground}
                    inputBackgroundColor={
                      darkMode ? "rgba(90,90,90,0.5)" : "rgba(225,225,225,0.5)"
                    }
                    data={data}
                    setData={setData}
                    sources={sources}
                    productType={productType}
                    criteriaList={criteriaList}
                    setCriteriaList={setCriteriaList}
                    chatMessages={chatMessages}
                    setChatMessages={setChatMessages}
                    websocketRef={websocketRef}
                    relatedQueries={relatedQueries}
                    setRelatedQueries={setRelatedQueries}
                    waitingForResponse={waitingForResponse}
                    setWaitingForResponse={setWaitingForResponse}
                    listings={listings}
                    handleClearChat={handleClearChat}
                    isMobile={isMobile}
                    userInteracted={userInteracted}
                    setUserInteracted={setUserInteracted}
                    waitingForChatHistory={waitingForChatHistory}
                  />
                ) : (
                  <Blog />
                )}
              </div>
              {chatMessages.length > 1 && !reviewChatToggle && (
                <div style={{ width: "23%" }}>
                  <Blackboard
                    textColor={darkMode ? darkmodeTextColor : defaultTextColor}
                    backgroundColor={darkMode ? darkmodeBackground : defaultBackground}
                    data={data}
                    setData={setData}
                    productType={productType}
                    criteriaList={criteriaList}
                    websocketRef={websocketRef}
                    listings={listings}
                    isMobile={isMobile}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default App;