import { Slider, TextField } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    color: "#4169E1",
    height: 8,
  },
  thumb: {
    height: 24, // Increased size of thumb
    width: 24, // Increased size of thumb
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
  mark: {
    // Added mark style to center the tickers
    backgroundColor: "#bfbfbf",
    height: 14,
    width: 2,
    marginTop: -3,
  },
  markActive: {
    // Added markActive style to center the active tickers
    backgroundColor: "inherit",
  },
});

function ContinuousCriteriaComponent({
  item,
  data,
  setData,
  textColor,
  textFieldFocusColor,
}) {
  const classes = useStyles();
  const StyledTextField = withStyles({
    root: {
      "& label.Mui-focused": {
        color: textFieldFocusColor,
        fontSize: "0.85em", // Smaller text
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: textFieldFocusColor,
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: textFieldFocusColor,
        },
      },
      "& .MuiInputBase-input": {
        fontSize: "0.85em", // Smaller text
      },
    },
  })(TextField);

  const handleSliderChange = (id, newValue) => {
    const updatedData = data.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          sliderLowerBound: newValue[0],
          sliderUpperBound: newValue[1],
        };
      }
      return item;
    });
    setData(updatedData);
  };
  return (
    <>
      <p
        style={{
          fontSize: "0.85em", // Smaller text
          whiteSpace: "pre-wrap",
          lineHeight: "1.4",
          fontStyle: "normal",
          width: "70%",
        }}
      >
        {item.description}
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "8px",
        }}
      >
        <div style={{ width: "95%" }}>
          <Slider
            value={[
              parseFloat(item.sliderLowerBound),
              parseFloat(item.sliderUpperBound),
            ]}
            onChange={(event, newValue) =>
              handleSliderChange(item.id, newValue)
            }
            aria-labelledby="range-slider"
            valueLabelDisplay="auto"
            step={parseFloat(item.step)}
            marks
            min={parseFloat(item.lowerBound)}
            max={parseFloat(item.upperBound)}
            classes={{
              root: classes.root,
              thumb: classes.thumb,
              active: classes.active,
              valueLabel: classes.valueLabel,
              track: classes.track,
              rail: classes.rail,
              mark: classes.mark, // Added mark class for larger step marks
              markActive: classes.markActive, // Added markActive class for larger active step marks
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          color: textColor,
        }}
      >
        <StyledTextField
          label={`Lower Bound (${item.unit})`}
          value={item.sliderLowerBound}
          InputProps={{
            style: { color: textColor },
          }}
          style={{ color: textColor }}
          InputLabelProps={{
            style: { color: textColor },
          }}
        />
        <StyledTextField
          label={`Upper Bound (${item.unit})`}
          value={item.sliderUpperBound}
          InputProps={{
            style: { color: textColor },
          }}
          style={{ color: textColor }}
          InputLabelProps={{
            style: { color: textColor },
          }}
        />
      </div>
    </>
  );
}

function CategoricalCriteriaComponent({
  item,
  data,
  setData,
  textColor,
}) {
  
  const handleCheckboxChanged = (id, event, value) => {
    const updatedData = data.map((item) => {
      if (item.id === id) {
        if (event) {
          if (!item.chosen_values.includes(value)) {
            item.chosen_values.push(value);
          }
        } else {
          const index = item.chosen_values.indexOf(value);
          if (index > -1) {
            item.chosen_values.splice(index, 1);
          }
        }
      }
      return item;
    });
    setData(updatedData);
  };

  const processedValues = item.possible_values;
  return (
    <Grid container direction="column">
      {processedValues.map((value, index) => (
        <Grid item key={index}>
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: textColor }}
                onChange={(event) =>
                  handleCheckboxChanged(item.id, event.target.checked, value)
                }
                checked={item.chosen_values.includes(value)}
              />
            }
            label={<span style={{ fontSize: "0.9em" }}>{value}</span>} // Smaller text
            labelPlacement="end"
          />
        </Grid>
      ))}
    </Grid>
  );
}

export { ContinuousCriteriaComponent, CategoricalCriteriaComponent };
