import React from "react";
import { useEffect } from "react";

import ProductDisplayPage from "../../ProductDisplayPage/ProductDisplayPage";
import { SocketReceiveListings } from "../../ChatContainer/socket_utils";

const SingularProduct = ({
  product,
  popupOpen,
  setPopupOpen,
  websocketRef,
  listings,
  isMobile,
}) => {
  // Count the number of reviews by sentiment
  const sentimentCounts = product.reviews.reduce((acc, review) => {
    acc[review.sentiment] = (acc[review.sentiment] || 0) + 1;
    return acc;
  }, {});

  useEffect(() => {
    SocketReceiveListings(websocketRef, product["product name"]);
  }, []);
  const backend_host =
    window.location.hostname === "localhost"
      ? "http://localhost:8000"
      : "https://omni-backend.com";

  return (
    <div
      key={product.product}
      style={{
        backgroundColor: "white",
        width: "100%",
        cursor: "pointer",
        maxWidth: "500px",
        height: isMobile ? "400px" : "350px",
        transition: "background-color 0.3s ease",
        border: "1px solid grey",
        borderRadius: "15px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      onClick={(e) => {
        if (popupOpen == null) setPopupOpen(product.product);
      }}
    >
      {popupOpen === product.product && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
            cursor: "default",
          }}
        >
          <ProductDisplayPage
            backgroundColor={"#ffffff"}
            closePopup={() => setPopupOpen(null)}
            metadata={{
              id: product.product,
              metadata: product,
              title: product["product name"],
            }}
            websocketRef={websocketRef}
            listings={listings}
            isMobile={isMobile}
          />
        </div>
      )}
      <div
        style={{
          // backgroundColor: "white",
          paddingTop: "30px",
          width: "90%",
          height: isMobile ? "65%" : "60%",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          position: "relative",
        }}
      >
        {product.product_thumbnail ? (
          <img
            style={{
              width: isMobile ? "80%" : "100%",
              objectFit: "contain",
              maxWidth: "100%",
              maxHeight: "100%",
            }}
            src={JSON.parse(product.product_thumbnail)[0]}
          ></img>
        ) : (
          <div style={{ width: "100%", height: "100%" }}></div>
        )}
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "20%",
            background:
              "linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))",
          }}
        ></div>
      </div>
      <div
        style={{
          width: "100%",
          height: isMobile ? "20%" : "40%",
          padding: "10px",
        }}
      >
        <div style={{ marginBottom: "10px" }}>
          <p
            style={{
              margin: 0,
              fontWeight: "bold",
              whiteSpace: "nowrap", // Prevents text from wrapping to the next line
              overflow: "hidden", // Hides overflow text
              textOverflow: "ellipsis",
            }}
          >
            {product["product name"]}
          </p>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <p style={{ margin: 0 }}>
            Recommended by {product.source_summaries.length}
          </p>
          {product.source_summaries.slice(0, 3).map((source, index) => (
            <img
              key={index}
              src={`https://www.google.com/s2/favicons?domain=${source.url}`}
              alt={`Source ${index + 1}`}
              style={{ width: "16px", height: "16px" }}
            />
          ))}
        </div>
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <a
            href={product.listing_url || "https://www.amazon.com"}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              borderRadius: "20px",
              backgroundColor: "rgba(240, 240, 240, 0.5)",
              padding: "4px",
              paddingLeft: "15px",
              height: "40px",
              width: "70%",
              transition: "background-color 0.3s ease, color 0.3s ease",
              display: "flex",
              alignItems: "center", // Vertically centers the items
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "black";
              e.currentTarget.style.color = "white";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor =
                "rgba(240, 240, 240, 0.5)";
              e.currentTarget.style.color = "black";
            }}
            onClick={(e) => {
              e.stopPropagation();
              fetch(`${backend_host}/api/record_outbound_click/`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  listing: {
                    link: product.listing_url,
                    product_name: product["product name"],
                  },
                }),
              });
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <img
                src={
                  product.listing_url
                    ? `https://www.google.com/s2/favicons?domain=${
                        new URL(product.listing_url).hostname
                      }`
                    : "https://www.google.com/s2/favicons?domain=amazon.com"
                }
                alt="Amazon"
                style={{ width: "24px", height: "24px" }}
              />
              <p style={{ margin: 0 }}>
                ${parseFloat(product.price).toFixed(2)}
              </p>
            </div>
          </a>
          <div
            style={{
              width: "30%",
              borderRadius: "20px",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "rgba(240, 240, 240, 0.8)",
              height: "40px",
              alignItems: "center",
              transition: "background-color 0.3s ease, color 0.3s ease",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "black";
              e.currentTarget.style.color = "white";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor =
                "rgba(240, 240, 240, 0.8)";
              e.currentTarget.style.color = "black";
            }}
            onClick={() => {
              if (popupOpen == null) setPopupOpen(product.product);
            }}
          >
            <button style={{ margin: 0 }}>see more</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingularProduct;
