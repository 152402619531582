import ProductDisplayPage from "../../ProductDisplayPage/ProductDisplayPage";
import {ContinuousCriteriaComponent, CategoricalCriteriaComponent } from "./CriteriaComponent";
import RecommendationComponent from "./RecommendationComponent";
import { motion } from "framer-motion";
import { CloseButton, DownButton, UpButton } from "./CardButtons";

function BlackboardCard({
  i,
  item,
  data,
  setData,
  backgroundColor,
  textColor,
  textFieldFocusColor,
  windowWidth,
  popupOpen,
  setPopupOpen,
  clickTimeoutId,
  setClickTimeoutId,
  moveToTop,
  moveToBottom,
  removeData,
  buttonColor,
  websocketRef,
  listings,
  isMobile
}) {
  return (
    <motion.div
      style={{
        padding: "15px",
        margin: "7px",
        borderRadius: "5px", // Updated to make it less rounded
        border: "1px solid grey",
        display: "block",
        maxWidth: "100%",
        marginBottom: "5px",
        position: "relative",
        cursor: item.type === "recommendation" ? "pointer" : "default",
        backgroundColor:
          backgroundColor === "#1a1a1a"
            ? "rgba(111, 111, 111, 0.1)"
            : "rgba(111, 111, 111, 0.05)", // Added slightly grey background translucent
      }}
      key={item.id}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.2 } }}
      layout
      onClick={(e) => {
        if (
          clickTimeoutId === null &&
          popupOpen === null &&
          item.type === "recommendation"
        ) {
          setPopupOpen(item.id);
        }
      }}
    >
      {popupOpen === item.id && (
        <div style={{ cursor: "auto" }}>
          <ProductDisplayPage
            closePopup={() => setPopupOpen(null)}
            backgroundColor={backgroundColor}
            metadata={item}
            listings={listings}
            websocketRef={websocketRef}
            isMobile={isMobile}
          ></ProductDisplayPage>
        </div>
      )}
      <CloseButton
        removeData={removeData}
        item={item}
        buttonColor={buttonColor}
      />

      {/* This is the title of the blackboard item */}
      <h2
        style={{
          fontWeight: "bold",
          margin: 0,
          position: "relative",
          fontSize: "1.1em",
          top: "-8px", // Move the text higher up in the frame
          width: "80%",
          letterSpacing: "0.2px", // Added spacing between letters
        }}
      >
        {item.title
          .split(" ")
          .map((word) => {
            const isGibberish = !/^[a-zA-Z]+$/.test(word);
            return isGibberish
              ? word.toUpperCase()
              : word.charAt(0).toUpperCase() + word.slice(1);
          })
          .join(" ")}
      </h2>

      {/* This is the image of the blackboard item if it is a */}
      {item.type === "recommendation" ? (
        <RecommendationComponent item={item}></RecommendationComponent>
      ) : item.criterion_type === "numerical" ? (
        <ContinuousCriteriaComponent
          item={item}
          textColor={textColor}
          textFieldFocusColor={textFieldFocusColor}
          data={data}
          setData={setData}
        ></ContinuousCriteriaComponent>
      ) : item.criterion_type === "multiple_choice" ||
        item.criterion_type === "categorical" ? (
        <CategoricalCriteriaComponent
          item={item}
          textColor={textColor}
          data={data}
          setData={setData}
        ></CategoricalCriteriaComponent>
      ) : null}
    </motion.div>
  );
}

export default BlackboardCard;
