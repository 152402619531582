import React from 'react';

function Testimonials({ source_summaries, isMobile }) {

    //console.log(source_summaries);

    const otherSources = source_summaries.filter(source => !source.url.includes('youtube.com'));

    return (
        <div
            style={{
                flex: "1",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "10px",
                height: "100%",
                width: "100%",
            }}
        >
            <div style={{ height: "10px" }}></div>
            {!otherSources || otherSources.length === 0 ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                    <div style={{ fontSize: '1.5em' }}>No sources available. :(</div>
                </div>
            ) : (
                <div style={{ width: "100%", height: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <div style={{ width: "100%", height: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: "10px" }}>
                        <div style={{ width: "100%", height: "100%", overflow: "auto", whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                            <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))", gap: "10px", padding: "2%" }}>
                                {otherSources.map((source, index) => (
                                    <div key={index} style={{ marginBottom: "10px", display: "flex", flexDirection: "column", height: "100%" }}>
                                        <a
                                            href={source.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ textDecoration: "none", color: "inherit", height: "100%" }}
                                        >
                                            <div
                                                style={{
                                                    padding: "10px",
                                                    borderRadius: "5px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "flex-start",
                                                    alignItems: "center",
                                                    position: "relative",
                                                    overflowY: "auto",
                                                    border: "1px solid #000",
                                                    cursor: "pointer",
                                                    height: "100%",
                                                    transition: "transform 0.3s, background-color 0.3s",
                                                }}
                                                onMouseEnter={(e) => {
                                                    e.currentTarget.style.backgroundColor = "rgba(240, 240, 240, 0.5)";
                                                    e.currentTarget.style.transform = "scale(1.05)";
                                                }}
                                                onMouseLeave={(e) => {
                                                    e.currentTarget.style.backgroundColor = "";
                                                    e.currentTarget.style.transform = "scale(1)";
                                                }}
                                            >
                                                {source.url && (
                                                    <img
                                                        src={`https://www.google.com/s2/favicons?domain=${new URL(source.url).hostname}&sz=64`}
                                                        alt={source.title}
                                                        style={{
                                                            width: "30px",
                                                            height: "30px",
                                                            marginBottom: "10px",
                                                            borderRadius: "5px",
                                                        }}
                                                    />
                                                )}
                                                <div style={{ flex: 1, textAlign: "center" }}>
                                                    <strong
                                                        style={{
                                                            fontSize: "0.9em",
                                                            display: "block",
                                                            marginBottom: "2px",
                                                        }}
                                                    >
                                                        {source.title}
                                                    </strong>
                                                    <p style={{ fontSize: "0.8em", margin: "0" }}>
                                                        {source.comments.length > 100
                                                            ? source.comments.slice(0, 100) + "..."
                                                            : source.comments}
                                                    </p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Testimonials;
