import React, { useState, useEffect } from 'react';
import { waveform } from "ldrs";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import SingularProduct from './components/SingularProduct';



waveform.register();

function ProductDatabase({ websocketRef, productDatabaseListings, listings, isMobile}) {
    const [searchTerm, setSearchTerm] = useState('');
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [popupOpen, setPopupOpen] = useState(null); // Now holds the id of the open popup

    useEffect(() => {
        setIsLoading(false);
        //console.log('Product listings have changed.');
        if (productDatabaseListings['message'] !== undefined) {
            const newProducts = productDatabaseListings['message'].map(entry => entry['fields']);
            setProducts(newProducts);
        }
    }, [productDatabaseListings]);

    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            setIsLoading(true);
            e.target.value = ''; // Clear the input field after search
            if (
                websocketRef.current &&
                websocketRef.current.readyState === WebSocket.OPEN
            ) {
                //console.log("sending data to websocket");
                websocketRef.current.send(
                    JSON.stringify({
                        type: "productdatabase_search",
                        query: searchTerm,
                    })
                );
            }
            else {
                //console.log("websocket not ready");
                setIsLoading(false);
            }
        }
    };

    return (
        <div style={{ width: "100%", height: '100%', overflow: 'auto', paddingRight: '5%' }}>

            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "20px", marginTop: "3%" }}>
                <h1 style={{ fontSize: "1.8em", fontWeight: "bold", margin: "0" }}>Discover Products</h1>
                <p style={{ fontSize: "1.1em", color: "#555", marginTop: "10px", textAlign: "center" }}>Know the item you're looking for? Here is the place to learn more!</p>
            </div>

            <div style={{ padding: "20px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <input
                    type="text"
                    placeholder="Search for a product..."
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={handleSearch}
                    style={{
                        padding: "12px 15px",
                        border: "none",
                        width: "calc(95% - 2*22px)",
                        textAlign: "left",
                        fontSize: "0.9em",
                        height: "80%",
                        borderRadius: "10px",
                        backgroundColor: "rgb(150,150,150,0.3)",
                        resize: "none",
                        margin: "0 auto",
                        display: "block"
                    }}
                />
            </div>
            {/* Results header */}
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", marginTop:"1%" }}>
                <h1
                    style={{
                        fontSize: "1.4em",
                        fontWeight: "bold",
                        margin: "0",
                        padding: "5px 10px",
                        display: "flex",
                        width: "calc(95% - 2*22px)",
                        boxSizing: "border-box",
                    }}
                >
                    <PlaylistAddIcon style={{ marginRight: "8px" }} />
                    Products 
                    {isLoading ? " (loading...)" : ` (${products.length} products)`}
                </h1>
            </div>
            {/* Display items based on search term */}
            {isLoading ? (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", marginTop:"1%"}}>
                   <l-waveform
                        size="35"
                        stroke="3.5"
                        speed="1"
                    ></l-waveform>
                </div>
            ) : products.length === 0 ? (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", marginTop:"1%"}}>
                    <p style={{ textAlign: "center", fontSize: "1.1em" }}>Nothing found yet. Try a different search query!</p>
                </div>
            ) : (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", marginTop:"1%"}}>
                    <div style={{  width: "calc(95% - 2*22px)", maxHeight: "55vh", overflowY: "auto", display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))", gap: "20px", justifyContent: "center", boxSizing: "border-box" }}>
                        {products.slice(0,50).map((product, index) => (
                            <div key={product.product} style={{display: "flex", minWidth: "250px"}}
                                onClick={() => {
                                }}
                            >
                                    <SingularProduct 
                                        product={product}
                                        popupOpen={popupOpen} 
                                        setPopupOpen={setPopupOpen}
                                        websocketRef={websocketRef} 
                                        listings={listings}
                                        isMobile={isMobile}
                                    />

                            </div>
                        ))}
                    </div>
                </div>
            )}
            
        
        </div>
    );
}
export default ProductDatabase;
