const FeedbackButton = ({ setFeedbackFormOpen }) => {
  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        top: "50%",
        transform: "translateY(-50%)",
        writingMode: "vertical-rl",
        textOrientation: "mixed",
        backgroundColor: "grey",
        color: "white",
        padding: "10px 5px",
        borderRadius: "0 5px 5px 0",
        cursor: "pointer",
        zIndex: 1000,
        transition:
          "transform 0.2s ease-out, padding 0.2s ease-out, opacity 0.3s ease-in",
        opacity: 1,
      }}
      onMouseEnter={(e) => {
        e.target.style.padding = "15px 8px";
        e.target.style.fontSize = "1.1em";
      }}
      onMouseLeave={(e) => {
        e.target.style.padding = "10px 5px";
        e.target.style.fontSize = "1em";
      }}
      onClick={() => {
        setFeedbackFormOpen(true);
      }}
    >
      Feedback
    </div>
  );
};

export default FeedbackButton;
