import React, { useRef } from "react";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import { motion } from "framer-motion";

const FeedbackForm = ({
  setFeedbackFormOpen,
  feedbackSubmitted,
  setFeedbackSubmitted,
  setSelectedFeedback,
  selectedFeedback,
  backend_host,
}) => {
  const textareaRef = useRef(null);
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          zIndex: 999,
        }}
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            setFeedbackFormOpen(false);
          }
        }}
      />
      <motion.div
        initial={{ y: "100%" }}
        animate={{ y: 0 }}
        transition={{ duration: 0.2, ease: "easeOut" }}
        style={{
          position: "fixed",
          right: "50px",
          bottom: "50px",
          display: "flex",
          flexDirection: "column",
          zIndex: 1000,
          height: "500px",
          width: "500px",
          backgroundColor: "white",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "30%",
            width: "100%",
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            backgroundColor: "grey",
          }}
        >
          <h1
            style={{
              fontSize: "1.5em",
              color: "white",
              padding: "30px",
              margin: "0",
              textAlign: "center",
            }}
          >
            Tell us what you think! We read all the suggestions.
          </h1>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50%",
            width: "100%",
            backgroundColor: "white",
            borderBottomLeftRadius: "10px",
            flexDirection: "column",
            borderBottomRightRadius: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "30px",
            }}
          >
            <SentimentSatisfiedAltIcon
              style={{
                fontSize: "4em",
                color: selectedFeedback === "happy" ? "green" : "grey",
                margin: "0 10px",
                cursor: "pointer",
                fontWeight: "normal",
              }}
              onClick={() => setSelectedFeedback("happy")}
            />
            <SentimentNeutralIcon
              style={{
                fontSize: "4em",
                color: selectedFeedback === "neutral" ? "orange" : "grey",
                margin: "0 10px",
                cursor: "pointer",
              }}
              onClick={() => setSelectedFeedback("neutral")}
            />
            <SentimentVeryDissatisfiedIcon
              style={{
                fontSize: "4em",
                color: selectedFeedback === "sad" ? "red" : "grey",
                margin: "0 10px",
                cursor: "pointer",
              }}
              onClick={() => setSelectedFeedback("sad")}
            />
          </div>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              width: "80%",
            }}
          >
            {feedbackSubmitted ? (
              <p
                style={{
                  textAlign: "center",
                  marginBottom: "10px",
                  padding: "10px",
                }}
              >
                Thank you for your feedback!
              </p>
            ) : (
              <>
                <textarea
                  ref={textareaRef}
                  placeholder="Enter your feedback here..."
                  style={{
                    width: "100%",
                    height: "100px",
                    marginBottom: "10px",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    resize: "vertical",
                  }}
                />
                <button
                  type="submit"
                  style={{
                    padding: "10px",
                    backgroundColor: "grey",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    // Prepare the feedback data
                    const feedbackData = {
                      text: textareaRef.current.value,
                      sentiment: selectedFeedback,
                    };

                    // Send the HTTP request
                    fetch(backend_host + "/api/post_feedback/", {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(feedbackData),
                    })
                      .then((response) => response.json())
                      .then((data) => {
                        console.log("Feedback submitted successfully:", data);
                        setFeedbackSubmitted(true);
                        setSelectedFeedback(null);
                        textareaRef.current.value = "";
                      })
                      .catch((error) => {
                        console.error("Error submitting feedback:", error);
                      });
                  }}
                >
                  Submit Feedback
                </button>
              </>
            )}
          </form>
        </div>
      </motion.div>
    </>
  );
};

export default FeedbackForm;
