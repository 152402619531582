import MaterialIcon from "material-icons-react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SendIcon from "@mui/icons-material/Send";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useState } from "react";

function ChatInput({
  chatMessages,
  handleUserMessage,
  setIsChatbotTyping,
  isChatbotTyping,
  textColor,
  inputBackgroundColor,
  textboxBackgroundColor,
  shadowColor,
  userInteracted,
  setUserInteracted,
  handleClearChat,
  isMobile, // Added isMobile prop
}) {
  const [inputValue, setInputValue] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  const handleSendMessage = (message) => {
    if (message.trim() !== "") {
      handleUserMessage(message);
      setUserInteracted(false);
      setInputValue("");
      setIsTyping(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: isMobile ? "95%" : "100%",
        position: isMobile ? "fixed" : "static", // Fixed position if on mobile
        bottom: isMobile ? "20px" : "auto", // 20px from the bottom if on mobile
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "10px",
          height: chatMessages.length === 1 ? "70px" : "50px",
          fontSize: "0.9em",
          color: textColor,
          background: textboxBackgroundColor,
          width: isMobile ? "95%" : (chatMessages.length === 1 ? "70%" : "95%"),
          boxShadow: `0 0 10px 2px ${shadowColor}`,
          alignSelf: "center",
          outline: isTyping ? `2px solid ${textColor}` : "none", // Conditional outline
        }}
      >
        {chatMessages.length === 1 ? (
          <>
            <textarea
              placeholder="Ask anything to begin..."
              style={{
                padding: "10px 15px",
                border: "none",
                width: "90%",
                textAlign: "left",
                color: textColor,
                background: inputBackgroundColor,
                fontSize: "1em",
                height: "80%",
                borderRadius: "10px 0 0 10px",
                resize: "none",
                outline: "none", // This removes the blue indicator
              }}
              value={inputValue}
              onKeyPress={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleSendMessage(e.target.value);
                }
              }}
              onChange={(e) => {
                setInputValue(e.target.value);
                setIsTyping(e.target.value !== "");
                if (e.target.value === "") {
                  setIsChatbotTyping(false);
                }
              }}
              onFocus={() => setIsTyping(true)}
              onBlur={() => setIsTyping(inputValue !== "")}
            />
            <IconButton
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "10px 10px 10px 10px",
                backgroundColor: inputValue.trim() === "" ? "grey" : "black",
                marginLeft: "auto",
                marginRight: "10px",
              }}
              aria-label="up"
              onMouseEnter={(e) => {
                if (inputValue.trim() !== "") {
                  e.currentTarget.style.backgroundColor = "rgb(50, 50, 50)";
                }
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = inputValue.trim() === "" ? "grey" : "black";
              }}
              onClick={() => {
                const inputElement = document.querySelector(
                  "textarea, input[type='text']"
                );
                if (inputElement) {
                  handleSendMessage(inputElement.value);
                }
              }}
              disabled={inputValue.trim() === ""}
            >
              <ArrowUpwardIcon style={{ color: "white" }} />
            </IconButton>
          </>
        ) : (
          <input
            type="text"
            placeholder="Type a message..."
            style={{
              padding: "15px",
              border: "none",
              width: "98%",
              textAlign: "left",
              fontFamily: "'Barlow', sans-serif",
              color: textColor,
              background: inputBackgroundColor,
              fontSize: "1em",
              height: "80%",
              borderRadius: "10px 0 0 10px",
              outline: "none",
            }}
            value={inputValue}
            onKeyPress={(e) => {
              if (e.key === "Enter" && e.target.value.trim() !== "") {
                handleSendMessage(e.target.value);
              }
            }}
            onChange={(e) => {
              setInputValue(e.target.value);
              setIsTyping(e.target.value !== "");
              if (e.target.value === "") {
                setIsChatbotTyping(false);
              }
            }}
            onFocus={() => setIsTyping(true)}
            onBlur={() => setIsTyping(inputValue !== "")}
            disabled={isChatbotTyping}
          />
        )}
      </div>
      {chatMessages.length > 1 && (
        <>
          <IconButton
            style={{
              marginLeft: "10px",
              width: "50px",
              height: "50px",
              borderRadius: "10px",
              backgroundColor: inputValue.trim() === "" ? "rgb(227 227 227)" : "black",
              transition: "background-color 0.3s ease",
            }}
            aria-label="up"
            onClick={() => {
              const inputElement = document.querySelector(
                "textarea, input[type='text']"
              );
              if (inputElement) {
                handleSendMessage(inputElement.value);
              }
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "rgb(50, 50, 50)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor =
                inputValue.trim() === "" ? "rgb(227 227 227)" : "black";
            }}
            disabled={inputValue.trim() === ""}
          >
            <ArrowUpwardIcon style={{ color: inputValue.trim() === "" ? "black" : "white" }} />
          </IconButton>
          <IconButton
            style={{
              marginLeft: "10px",
              width: "50px",
              height: "50px",
              borderRadius: "10px",
              backgroundColor: "rgb(227 227 227)",
            }}
            aria-label="delete"
            onClick={() => {
              handleClearChat();
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "#b04545";
              e.currentTarget.querySelector("svg").style.color = "white";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "rgb(227 227 227)";
              e.currentTarget.querySelector("svg").style.color = "black";
            }}
          >
            <RestartAltIcon style={{ fontSize: "25px", color: "black" }} />
          </IconButton>
        </>
      )}
    </div>
  );
}

export default ChatInput;