import React, { useState } from 'react';
import Reviews from "./components/Reviews";
import NewReviews from "./components/NewReviews";
import Details from "./components/Details";
import LeftPanel from "./components/LeftPanel";
import Testimonials from "./components/Testimonials";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Videoboard from "./components/Videoboard";


function ProductDisplayPage({
  closePopup,
  backgroundColor,
  metadata,
  listings,
  isMobile
}) {
  const [activeTab, setActiveTab] = useState('General');

  const youtubeSources = metadata.metadata.source_summaries.filter(source => source.url.includes('youtube.com'));
  const tabs = ['General', 'Reviews', 'Articles'];
  if (youtubeSources.length > 0) {
    tabs.splice(1, 0, 'Videos'); // Insert 'Videos' tab after 'General' if there are YouTube sources
  }

  const renderContent = () => {
    switch (activeTab) {
      case 'General':
        return isMobile ? (
          <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "100%", overflowY: "auto" }}>
            <LeftPanel metadata={metadata} listings={listings} isMobile={isMobile} />
            <Details metadata={metadata} backgroundColor={backgroundColor} listings={listings} isMobile={isMobile} />
          </div>
        ) : (
          <div style={{ display: "flex", width: "100%", height: "100%" }}>
            <div style={{ width: "50%", overflowY: "auto" }}>
              <LeftPanel metadata={metadata} listings={listings} isMobile={isMobile} />
            </div>
            <div style={{ width: "50%", overflowY: "auto" }}>
              <Details metadata={metadata} backgroundColor={backgroundColor} listings={listings} isMobile={isMobile} />
            </div>
          </div>
        );
      case 'Price History': 
        return (
          <h1>Price History</h1>
        );
      case 'Videos':
        return (
          <Videoboard youtubeSources={youtubeSources} isMobile={isMobile} />
        );
      case 'Reviews':
        return metadata.metadata && metadata.metadata.reviews && metadata.metadata.bullet_points ? (
          <NewReviews reviews={metadata.metadata.reviews} bulletPoints={metadata.metadata.bullet_points} isMobile={isMobile}/>
        ) : null;
      case 'Articles': 
        return (
          <Testimonials source_summaries={metadata.metadata.source_summaries} isMobile={isMobile} />
        );
      default:
        return null;
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        zIndex: "1000",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          backgroundColor: backgroundColor,
          padding: "20px",
          borderRadius: "10px",
          width: "90%",
          height: "90%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div style={{ width: "100%", position: "relative" }}>
          <div style={{ 
            position: "absolute", 
            top: isMobile ? "50%" : "5px", 
            right: "5px", 
            transform: isMobile ? "translateY(-50%)" : "none" 
          }}>
            <IconButton aria-label="close" onClick={closePopup}>
              <CloseIcon sx={{ color: "black" }} />
            </IconButton>
          </div>
          <h1
            style={{
              textAlign: "center",
              fontSize: isMobile ? "1.2em" : "2.5em",
              fontWeight: "bold",
              paddingTop: isMobile ? "5px" : "5px",
              width: isMobile ? "90%" : "auto"
            }}
          >
            {metadata.title
              .split(" ")
              .map((word) => {
                const isGibberish = !/^[a-zA-Z]+$/.test(word);
                return isGibberish
                  ? word.toUpperCase()
                  : word.charAt(0).toUpperCase() + word.slice(1);
              })
              .join(" ")}
          </h1>
          <hr style={{ height: "2px" }} />
        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            overflowY: "auto",
            overflowX: "hidden", // Prevent horizontal scrolling
          }}
        >
          <div
            style={{
              flex: isMobile ? "0 0 auto" : "0 0 15%",
              padding: "10px",
              borderRight: isMobile ? "none" : "1px solid #ddd",
              borderBottom: isMobile ? "1px solid #ddd" : "none",
              position: isMobile ? "static" : "sticky",
              top: "0",
              width: isMobile ? "100%" : "auto",
              height: isMobile ? "auto" : "100%",
              overflowX: isMobile ? "auto" : "visible", // Make the tab bar scrollable in X direction if on mobile
              whiteSpace: isMobile ? "nowrap" : "normal", // Prevent wrapping of tabs if on mobile
            }}
          >
            <ul
              style={{
                listStyleType: "none",
                padding: 0,
                display: "flex",
                flexDirection: isMobile ? "row" : "column",
              }}
            >
              {tabs.map(
                (
                  tab // we should add Price History and Sources soon
                ) => (
                  <li
                    key={tab}
                    style={{
                      marginBottom: isMobile ? "0" : "10px",
                      marginRight: isMobile ? "10px" : "0",
                    }}
                  >
                    <button
                      onClick={() => setActiveTab(tab)}
                      style={{
                        backgroundColor: activeTab === tab ? "#000" : "#f2f2f2",
                        color: activeTab === tab ? "#fff" : "#000",
                        border: "none",
                        padding: "10px",
                        width: "100%",
                        textAlign: "left",
                        cursor: "pointer",
                        borderRadius: "5px",
                        transition: "background-color 0.3s",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor =
                          activeTab === tab ? "#333" : "#e0e0e0";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor =
                          activeTab === tab ? "#000" : "#f2f2f2";
                      }}
                    >
                      {tab}
                    </button>
                  </li>
                )
              )}
            </ul>
          </div>
          <div style={{ flex: "1", padding: "10px" }}>{renderContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default ProductDisplayPage;