import { useState, useRef, useEffect } from "react";
import Sources from "../Sources/Sources";
import { waveform } from "ldrs";

import FollowupQueries from "./components/FollowupQueries";
import ChatInput from "./components/ChatInput";
import { motion } from 'framer-motion';
import { openCriteria } from "./socket_utils";

import MessagesContainer from "./components/MessagesContainer";

waveform.register();

function ChatContainer({
  textColor = "#000000",
  backgroundColor = "#1a1a1a",
  textboxBackgroundColor = "#ffffff",
  inputBackgroundColor = "rgba(150,150,150,0.5)",
  shadowColor = "ffffff",
  setData,
  data,
  sources,
  productType,
  setCriteriaList,
  criteriaList,
  chatMessages,
  setChatMessages,
  websocketRef,
  relatedQueries,
  setRelatedQueries,
  waitingForResponse,
  setWaitingForResponse,
  listings,
  handleClearChat,
  isMobile,
  userInteracted,
  setUserInteracted,
  waitingForChatHistory,
}) {
  const [isChatbotTyping, setIsChatbotTyping] = useState(false);

  const [showYCBacked, setShowYCBacked] = useState(true); // State to control the display of "Backed by YC"
  const chatContainerRef = useRef(null); // Ref for ChatContainer
  const [currentProductTypeIndex, setCurrentProductTypeIndex] = useState(0);
  const productTypes = [
    "electronics",
    "headphones",
    "monitors",
    "laptops",
    "cameras",
    "microphones",
    "speakers",
    "tablets",
    "VR devices",
    "air purifiers", 
    "printers", 
    "TVs",
    "robot vacuums",
    "smartphones"
  ];

  useEffect(() => {
    // console.log("chatMessages: ", chatMessages);
    if (isMobile) {
      setUserInteracted(true);
    }
  }, [chatMessages]);

  useEffect(() => {
    const smoothScrollToBottom = () => {
      if (
        chatContainerRef.current &&
        !userInteracted &&
        chatMessages.length > 1
      ) {
        const scrollHeight = chatContainerRef.current.scrollHeight;
        const height = chatContainerRef.current.clientHeight;
        const maxScrollTop = scrollHeight - height;
        chatContainerRef.current.scrollTo({
          top: maxScrollTop,
          behavior: "smooth",
        });
      }
    };

    smoothScrollToBottom();

    const handleUserInteraction = (event) => {
      if (event.isTrusted) {
        setUserInteracted(true);
        setShowYCBacked(false); // Hide the "Backed by YC" message when the user interacts
      }
    };

    const currentChatContainerRef = chatContainerRef.current;
    if (currentChatContainerRef) {
      currentChatContainerRef.addEventListener("wheel", handleUserInteraction);
    }

    return () => {
      if (currentChatContainerRef) {
        currentChatContainerRef.removeEventListener(
          "wheel",
          handleUserInteraction
        );
      }
    };
  }, [chatMessages, relatedQueries, userInteracted]);

  useEffect(() => {
    if (chatContainerRef.current && chatMessages.length === 1) {
      chatContainerRef.current.scrollTop = 0; // Scroll to top
    }
  }, [chatMessages]);

  useEffect(() => {
    //console.log("New productType: ", productType, criteriaList);
  }, [productType]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentProductTypeIndex(
        (prevIndex) => (prevIndex + 1) % productTypes.length
      );
    }, 3500);

    return () => clearInterval(interval);
  }, []);

  const handleUserMessage = async (userMessage) => {
    const newUserMessage = {
      message: userMessage,
      sender: "user",
    };

    const updatedChatMessages = [...chatMessages, newUserMessage];
    setChatMessages(updatedChatMessages);
    setShowYCBacked(false); // Hide the "Backed by YC" message when a new message is sent

    setIsChatbotTyping(true);
    setWaitingForResponse(true);

    try {
      //console.log("sending message to websocket");
      setRelatedQueries([]);
      websocketRef.current.send(
        JSON.stringify({
          type: "default",
          query: userMessage,
          criteria: data.filter((item) => item.type === "criteria"),
          n: 5,
        })
      );
    } catch (error) {
      console.error("Error:", error);
    }

    setIsChatbotTyping(false);
  };

  const isOverflowing = () => {
    if (chatContainerRef.current) {
      return (
        chatContainerRef.current.scrollHeight >=
        chatContainerRef.current.clientHeight
      );
    }
    return false;
  };

  const getProductTypeMessage = () => {
    const productType = productTypes[currentProductTypeIndex] || "electronics";
    return `<b>${productType}</b>`;
  };

  return (
    <div
      style={{
        height: isMobile ? "90vh" : chatMessages.length === 1 ? "83vh" : "97vh",
        width: "100%", // chatMessages.length === 1 ? "100%" : "160%",
        borderRadius: "5px",
        overflow: "hidden",
        padding: "1% 6% 1% 6%",
        paddingTop: isOverflowing()
          ? "1%"
          : chatMessages.length > 1
          ? "5%"
          : isMobile
          ? "10vh"
          : "10vh",
        paddingBottom: "2%",
        transition: "height 1s ease-in-out",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {chatMessages.length > 1 && (
        <MessagesContainer
          chatMessages={chatMessages}
          chatContainerRef={chatContainerRef}
          textColor={textColor}
          inputBackgroundColor={inputBackgroundColor}
          websocketRef={websocketRef}
          waitingForResponse={waitingForResponse}
          openCriteria={openCriteria}
          handleUserMessage={handleUserMessage}
          relatedQueries={relatedQueries}
          product_type={productType}
          listings={listings}
          userInteracted={userInteracted}
          setUserInteracted={setUserInteracted}
          isMobile={isMobile}
          sources={sources}
        />
      )}

      {/* This is the suggested text */}
      <div style={{ margin: "10px" }}></div>
      {chatMessages.length === 1 && (
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <h1
            style={{
              fontSize: isMobile ? "3em" : "5em",
              color: textColor,
              margin: "0",
              fontWeight: "bold",
            }}
          >
            kart.ai
          </h1>
          <span
            style={{ fontSize: isMobile ? "1em" : "1.5em", color: textColor }}
          >
            Your shopping assistant for{" "}
            <motion.span
              style={{ color: textColor }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              dangerouslySetInnerHTML={{ __html: getProductTypeMessage() }}
              key={currentProductTypeIndex} // Add key to trigger animation on change
            />
          </span>
        </div>
      )}
      {/* input text box */}

      {!waitingForChatHistory && (
        <ChatInput
          chatMessages={chatMessages}
          handleUserMessage={handleUserMessage}
          setIsChatbotTyping={setIsChatbotTyping}
          isChatbotTyping={isChatbotTyping}
          textColor={textColor}
          inputBackgroundColor={inputBackgroundColor}
          textboxBackgroundColor={textboxBackgroundColor}
          shadowColor={shadowColor}
          userInteracted={userInteracted}
          setUserInteracted={setUserInteracted}
          handleClearChat={handleClearChat}
          isMobile={isMobile}
        />
      )}

      {chatMessages.length === 1 && (
        <FollowupQueries
          handleUserMessage={handleUserMessage}
          backgroundColor={backgroundColor}
          textColor={textColor}
          isMobile={isMobile}
          buttonTexts={
            isMobile
              ? [
                  {
                    text: "Recommend me a webcam for work-from-home zoom calls.",
                    icon: "videocam",
                  },
                  {
                    text: "How does the iPad Pro compare to the iPad Air?",
                    icon: "keyboard",
                  },
                ]
              : [
                  {
                    text: "Recommend me a webcam for work-from-home zoom calls.",
                    icon: "videocam",
                  },
                  {
                    text: "What criteria should I consider when buying a keyboard?",
                    icon: "keyboard",
                  },
                  {
                    text: "Where can I buy a Samsung Odyssey G5 monitor?",
                    icon: "desktop_windows",
                  },
                  {
                    text: "How does the iPad Pro compare to the iPad Air?",
                    icon: "tablet",
                  },
                ]
          }
        />
      )}

      
{chatMessages.length === 1 && (
      <span style={{ fontSize: "0.8em", position: "absolute", bottom: isMobile ? "75%" : "17%", padding: "0 10%", textAlign: "center", width: "100%" }}>
        We are in the process of acquiring Amazon Affiliate status! <br></br>This does not affect product rankings: we are committed to bringing objective, unbiased shopping to all.
      </span>
)}
      {/* Backed by YC message */}
      {showYCBacked && chatMessages.length === 1 && !isMobile && (
        <motion.div
          style={{
            position: "absolute",
            bottom: "5%",
            textAlign: "center",
            width: "auto",
            color: textColor,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer", // Add cursor pointer to indicate clickable
          }}
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          whileHover={{ scale: 1.05 }} // Make the button look slightly larger when hovered over
          onClick={() =>
            window.open(
              "https://www.ycombinator.com/companies/kart-ai",
              "_blank"
            )
          }
          onViewportBoxUpdate={(viewportBox) => {
            const { x, y } = viewportBox;
            // Add logic to handle position change on window resize
            window.addEventListener("resize", () => {
              const newY = window.innerHeight * 0.08; // 8% from bottom
              const newX = (window.innerWidth - viewportBox.width) / 2; // Center horizontally
              motion.div.start({
                x: newX,
                y: newY,
                transition: { duration: 0.5, ease: "easeInOut" },
              });
            });
          }}
        >
          <span style={{ fontSize: "1.2em" }}>Backed by </span>
          <img
            src="https://i0.wp.com/www.vccafe.com/wp-content/uploads/2017/09/Y_Combinator_logo_text_wordmark.png?ssl=1"
            alt="Y Combinator Logo"
            style={{ maxWidth: "160px" }}
          />
        </motion.div>
      )}
    </div>
  );
}

export default ChatContainer;
