import ProsCons from "./ProsCons";
import NewListings from "./NewListings";
import Listings from "./Listings";

function Details({ metadata, backgroundColor, listings, isMobile }) {
  return (
    <div style={{ textAlign: "center" }}>
      {/* <h2 style={{fontWeight: "bold", fontSize: "1.8em", marginBottom: "20px"}}> Details</h2> */}
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {Object.keys(metadata.metadata.specs).length > 0 && (
          <div
            style={{
              width: "90%",
              display: "grid",
              marginTop: "3%",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "5px",
              textAlign: "center",
              border: "1px solid black",
              padding: "5px",
            }}
          >
            {Object.entries(metadata.metadata.specs).map(
              ([key, value], index) => (
                <div key={key} style={{ padding: "1px 0" }}>
                  <strong style={{ fontSize: "1.1em" }}>
                    {key
                      .replace(/_/g, " ")
                      .replace(/\b\w/g, (char) => char.toUpperCase())}
                    :
                  </strong>
                  {Array.isArray(value) ? (
                    /^\d+(\.\d+)?$/.test(value[0].toString()) ? (
                      <p style={{ fontSize: "0.8em" }}>{value.join(", ")}</p>
                    ) : (
                      value.map((val, idx) => (
                        <p key={idx} style={{ fontSize: "0.8em", margin: "0" }}>
                          {val}
                        </p>
                      ))
                    )
                  ) : (
                    <p style={{ fontSize: "0.8em" }}>{value || "Unknown"}</p>
                  )}
                </div>
              )
            )}
          </div>
        )}
      </div> */}

      {metadata.metadata.insights && <ProsCons metadata={metadata} />}
      {metadata.metadata.specs && (
        <>
          <h3
            style={{
              fontSize: "1.5em",
              fontWeight: "bold",
              textAlign: "center",
              paddingBottom: "5px",
              marginTop: "2%",
            }}
          >
            Specifications
          </h3>
          <table style={{ width: "100%", margin: "3% auto", borderCollapse: "collapse" }}>
            <thead>
              <tr style={{ backgroundColor: "#f8f8f8" }}>
                <th style={{ padding: "12px", textAlign: "left", fontWeight: "bold", borderBottom: "2px solid #ddd" }}>Features</th>
                <th style={{ padding: "12px", textAlign: "left", fontWeight: "bold", borderBottom: "2px solid #ddd" }}>Details</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(metadata.metadata.specs).map(([key, value], index) => (
                <tr key={index} style={{ borderBottom: "1px solid #ddd", backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff" }}>
                  <td style={{ padding: "12px", textAlign: "left", fontWeight: "bold", color: "#333" }}>
                    {key.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())}
                  :
                  </td>
                  <td style={{ padding: "12px", textAlign: "left", color: "#555" }}>
                    {Array.isArray(value) ? value.join(", ") : value}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
      {/* <NewListings listings={listings} product_name={metadata.title} isMobile={isMobile}/> */}

      {/* <div style={{ height: "15%" }}></div>
      <h3
        style={{
          fontSize: "1.8em",
          fontWeight: "bold",
          paddingTop: "25px",
          textAlign: "center",
        }}
      >
        {" "}
        Sources{" "}
      </h3>
      {metadata.metadata.source_summaries &&
      metadata.metadata.source_summaries.length > 0 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
            padding: "0 10px",
            boxSizing: "border-box",
            marginTop: "20px",
          }}
        >
          {metadata.metadata.source_summaries
            .slice(0, isMobile ? 3 : metadata.metadata.source_summaries.length)
            .map((source, index) => (
              <a
                key={index}
                href={source.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    padding: "10px",
                    borderRadius: "5px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    position: "relative",
                    overflowY: "auto",
                    border: "1px solid #000",
                    cursor: "pointer",
                    // boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = "rgba(240, 240, 240, 0.5)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = "";
                  }}
                >
                  {source.url && (
                    <img
                      src={`https://www.google.com/s2/favicons?domain=${
                        new URL(source.url).hostname
                      }&sz=64`}
                      alt={source.title}
                      style={{
                        width: "30px",
                        height: "30px",
                        marginRight: "10px",
                        borderRadius: "5px",
                      }}
                    />
                  )}
                  <div style={{ flex: 1 }}>
                    <strong
                      style={{
                        fontSize: "0.9em",
                        display: "block",
                        marginBottom: "2px",
                      }}
                    >
                      {source.title}
                    </strong>
                    <p style={{ fontSize: "0.8em", margin: "0" }}>
                      {source.comments.length > 100
                        ? source.comments.slice(0, 100) + "..."
                        : source.comments}
                    </p>
                  </div>
                </div>
              </a>
            ))}
        </div>
      ) : (
        <p style={{ textAlign: "center" }}>
          No sources can be found at the current moment. Please refer to the
          reviews for more information!
        </p>
      )} */}
      {/* <p style={{ fontSize: "0.9em", paddingTop: "15px", textAlign: "left", paddingLeft: "20px", paddingRight: "20px" }}>
        <MuiMarkdown
          style={{ display: "block", textAlign: "left", lineHeight: "1.5" }}
          overrides={{
            // This keeps other default overrides
            ...getOverrides(),
            li: {
              component: "li",
              props: {
                style: {
                  marginTop: "5px",
                  fontWeight: "normal",
                  listStyleType: "disc",
                  marginLeft: "40px",
                  lineHeight: "1.6",
                },
              },
            },
            h3: {
              component: "h3",
              props: {
                style: {
                  fontWeight: "bold",
                  fontSize: "1.4em",
                },
              },
            },
            a: {
              component: "a",
              props: {
                style: {
                  fontSize: "1.2em",
                  marginTop: "10px",
                  color: "#4169E1",
                  fontWeight: "bold",
                  display: "block"
                },
                target: "_blank",
                rel: "noopener noreferrer"
              },
            },
          }}
        >
          {sourceSummaries[metadata.metadata["product"]] && sourceSummaries[metadata.metadata["product"]].length > 0
            ? sourceSummaries[metadata.metadata["product"]]
            : "No sources can be found at the current moment. Please refer to the reviews for more information!"}
        </MuiMarkdown>

        {/* {metadata.metadata.comments
          .replace(/<chunk>/g, "")
          .replace(/<\/chunk>/g, "")}
        <a
          href={metadata.metadata.source}
          style={{
            fontSize: "1.1em",
            marginTop: "10px",
            textDecoration: "underline",
            color: backgroundColor === "#1a1a1a" ? "#87CEFA" : "#4283edef",
          }}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(event) => event.stopPropagation()}
        >
          See more
        </a> */}
      {/* </p> */}
    </div>
  );
}

export default Details;
