import { MuiMarkdown } from "mui-markdown";
import RelatedQueries from "./RelatedQueries";
import SingularProduct from "../../ProductDatabase/components/SingularProduct";
import { useState, useEffect } from 'react';
import Listings from "../../ProductDisplayPage/components/Listings";
import Sources from "../../Sources/Sources";

function MessagesContainer({
  chatMessages,
  chatContainerRef,
  textColor,
  inputBackgroundColor,
  websocketRef,
  waitingForResponse,
  openCriteria,
  handleUserMessage,
  relatedQueries,
  product_type,
  listings,
  setUserInteracted,
  userInteracted,
  isMobile,
  sources,
}) {
  const [popupOpen, setPopupOpen] = useState(null);
  const [currentSlides, setCurrentSlides] = useState({});

  useEffect(() => {
    const newSlides = {};
    chatMessages.forEach((message, index) => {
      if (message.sender === "price" && !(index in currentSlides)) {
        newSlides[index] = 0;
      }
    });
    if (Object.keys(newSlides).length > 0) {
      setCurrentSlides((prev) => ({ ...prev, ...newSlides }));
    }
  }, [chatMessages]);

  const handlePrevClick = (slideshowId, messageLength) => {
    setCurrentSlides((prev) => ({
      ...prev,
      [slideshowId]: Math.max((prev[slideshowId] || 0) - 1, 0),
    }));
    const container = document.getElementById(
      `slideshow-container-${slideshowId}`
    );
    container.scrollBy({ left: -container.clientWidth, behavior: "smooth" });
  };

  const handleNextClick = (slideshowId, messageLength) => {
    setCurrentSlides((prev) => ({
      ...prev,
      [slideshowId]: Math.min((prev[slideshowId] || 0) + 1, messageLength - 1),
    }));
    const container = document.getElementById(
      `slideshow-container-${slideshowId}`
    );
    container.scrollBy({ left: container.clientWidth, behavior: "smooth" });
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     console.log(currentSlides);
  //   }, 2000);

  //   return () => clearInterval(interval);
  // }, [currentSlides]);

  return (
    <div
      ref={chatContainerRef}
      style={{
        padding:
          chatMessages.length === 1
            ? isMobile
              ? "2% 0% 15% 0%"
              : "5% 0% 0 0%"
            : "5% 0% 0 0%",
        height:
          chatMessages.length > 1
            ? isMobile
              ? "90%"
              : "100%"
            : isMobile
            ? "95%"
            : "100%",
        overflowY: "scroll",
        width: "100%",
        scrollBehavior: chatMessages.length === 1 ? "auto" : "smooth",
        overflowY: chatMessages.length === 1 ? "hidden" : "scroll",
        display: "flex",
        flexDirection: "column",
        transition: "height 0.5s ease-in-out",
      }}
    >
      {chatMessages.map((message, i) => (
        <div
          key={i}
          style={{
            padding: "5px 10px 5px 5px",
            margin: "2px",
            borderRadius: "5px",
            display: "block",
            maxWidth: "100%",
            fontSize:
              message.sender === "user"
                ? isMobile
                  ? "1.2em"
                  : "1.4em"
                : message.isHeader
                ? isMobile
                  ? "1.5em"
                  : "1.8em"
                : "inherit", // Updated to h1 size if message is a header
            fontWeight:
              message.sender === "user" || message.isHeader ? "bold" : "normal", // Updated to make user text and header bold
            color: textColor,
          }}
        >
          {
            message.sender === "user" && (
              <div
                style={{
                  borderBottom: "3px solid",
                  borderColor: textColor,
                  opacity: 1,
                  marginBottom: "25px",
                }}
              ></div>
            ) /* Super thick and visible div line */
          }

          {message.sender === "recommender" ? (
            <div
              style={{
                color: textColor,
                margin: "10px 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column", // Added to stack items vertically
              }}
            >
              {message.topic ? (
                <div
                  style={{
                    width: "100%",
                    marginBottom: "20px",
                    textAlign: "left",
                    // Added to make the text italics
                  }}
                >
                  <h3
                    style={{
                      fontSize: "1.2em",
                      display: "inline",
                      borderBottom: isMobile ? "none" : "1px solid",
                      borderColor: textColor,
                      paddingBottom: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {message.topic}
                  </h3>
                </div>
              ) : null}
              {/* message.message is a list */}
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
                  gap: "20px",
                  justifyContent: "center",
                  // alignItems: "stretch",
                  gridAutoRows: "1fr",

                  width: "100%",
                }}
              >
                {message.message.map((entry, index) => (
                  <div onClick={() => setUserInteracted(true)}>
                    <SingularProduct
                      product={JSON.parse(entry)}
                      popupOpen={popupOpen}
                      setPopupOpen={setPopupOpen}
                      websocketRef={websocketRef}
                      listings={listings}
                      isMobile={isMobile}
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : message.sender === "price" ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  margin: "auto",
                  position: "relative",
                }}
              >
                {/* {currentSlides[i] > 0 && (
                  <button
                    style={{
                      position: "absolute",
                      left: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      zIndex: 1,
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      fontSize: "2em",
                      color: "white",
                      padding: "30px 5px",
                      fontWeight: "bold",
                    }}
                    onClick={() =>
                      handlePrevClick(i, Object.keys(message.message).length)
                    }
                  >
                    <i className="material-icons">chevron_left</i>
                  </button>
                )}
                <div
                  id={`slideshow-container-${i}`}
                  style={{
                    display: "flex",
                    overflowX: "auto",
                    scrollSnapType: "x mandatory",
                    width: "100%",
                    scrollBehavior: "smooth",
                  }}
                >
                  {Object.entries(message.message).map(([key, entry]) => (
                    <div
                      key={key}
                      style={{
                        flex: "0 0 80%",
                        scrollSnapAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "80%",
                        margin: "0 5px",
                      }}
                    >
                      <h3 style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                        {key.toUpperCase()}:
                      </h3>
                      <Listings
                        listings={listings}
                        product_name={key}
                        isMobile={isMobile}
                      />
                    </div>
                  ))}
                </div>
                {currentSlides[i] < Object.keys(message.message).length - 1 && (
                  <button
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      zIndex: 1,
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      fontSize: "2em",
                      color: "white",
                      padding: "30px 5px",
                      fontWeight: "bold",
                    }}
                    onClick={() =>
                      handleNextClick(i, Object.keys(message.message).length)
                    }
                  >
                    <i className="material-icons">chevron_right</i>
                  </button>
                )} */}
              </div>
            </>
          ) : (
            <div
              style={{
                whiteSpace: "pre-wrap",
                lineHeight: "1.75",
                maxWidth: "100%",
                overflowX: "auto",
                fontSize: "1em",
              }}
            >
              <MuiMarkdown
                overrides={{
                  // This keeps other default overrides
                  br: {
                    component: "br",
                    props: {
                      style: {
                        margin: "1em 0", // Makes line breaks longer, adding more space between paragraphs
                      },
                    },
                  },
                  h3: {
                    component: "h3",
                    props: {
                      style: {
                        fontWeight: "bold",
                        borderColor: textColor,
                        fontSize: "1.2em",
                      },
                    },
                  },
                  li: {
                    component: "li",
                    props: {
                      style: {
                        listStyleType: "disc", // Added to make li elements appear as bullet points
                        marginLeft: "20px", // Added margin to align bullets properly
                      },
                    },
                  },

                  th: {
                    component: "th",
                    props: {
                      style: {
                        fontWeight: "bold",
                        borderColor: textColor,
                        padding: "8px",
                        textAlign: "left",
                        whiteSpace: "nowrap",
                        backgroundColor: inputBackgroundColor,
                        overflow: "hidden",
                      },
                    },
                  },
                  td: {
                    component: "td",
                    props: {
                      style: {
                        borderColor: textColor,
                        borderBottom: "1px solid",
                        padding: "8px",
                        textAlign: "left",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      },
                    },
                  },
                  button: {
                    component: "button",
                    props: {
                      style: {
                        color: "#4169E1",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      },
                      onClick: (event) => {
                        const text = event.target.innerText.trim();
                        if (text.endsWith("?")) {
                          handleUserMessage(text);
                        } else {
                          openCriteria(websocketRef, text.toString());
                        }
                      },
                    },
                  },
                }}
              >
                {message.message && message.message.slice(-4) === "null"
                  ? message.message.slice(0, -4)
                  : message.message}
              </MuiMarkdown>
            </div>
          )}
          {message.sender === "user" && (
            <Sources
              textColor={textColor}
              backgroundColor={inputBackgroundColor}
              sources={sources}
              isMobile={isMobile}
            />
          )}
        </div>
      ))}
      {waitingForResponse && (
        websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50px",
            }}
          >
            <l-waveform
              size="35"
              stroke="3.5"
              speed="1"
              color={textColor}
            ></l-waveform>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "50px",
              color: textColor,
              fontSize: "1.2em",
              paddingTop: "60px"
            }}
          >
            Sorry! An unexpected error with the connection has occurred, please reload.
            <div
              onClick={() => {
                if (chatMessages.length > 2) {
                  window.location.reload();
                } else {
                  const url = new URL(window.location.href);
                  url.hash = '';
                  window.location.href = url.toString();
                }
              }}
              style={{
                display: "inline-block",
                marginTop: "10px",
                padding: "5px 10px",
                fontSize: "0.9em",
                cursor: "pointer",
                color: textColor,
                border: `2px solid black`,
                borderRadius: "5px",
                backgroundColor: "#f0f0f0",
                textAlign: "center",
              }}
            >
              Reload
            </div>
          </div>
        )
      )}
      <RelatedQueries
        items={relatedQueries}
        textColor={textColor}
        handleUserMessage={handleUserMessage}
        product_type={product_type}
        userInteracted={userInteracted}
        setUserInteracted={setUserInteracted}
      />
    </div>
  );
}

export default MessagesContainer;