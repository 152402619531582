import React from 'react';

function Videoboard({ youtubeSources, isMobile }) {
    const getYoutubeEmbedUrl = (url) => {
        const urlObj = new URL(url);
        const videoId = urlObj.searchParams.get("v");
        return `https://www.youtube.com/embed/${videoId}`;
    };

    return (
        <div style={{ width: "100%", height: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <div style={{ width: "100%", height: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: "10px" }}>
                <div style={{ width: "100%", height: "100%", overflow: "auto", whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                    {youtubeSources.length > 0 && (
                        <div style={{ marginBottom: "20px" }}>
                            <div style={{ display: "grid", gridTemplateColumns: isMobile ? "1fr" : "repeat(auto-fit, minmax(400px, 1fr))", gap: "20px", padding: "2%" }}>
                                {youtubeSources.map((source, index) => (
                                    <div key={index} style={{ marginBottom: "10px", display: "flex", flexDirection: "column" }}>
                                        <div
                                            style={{
                                                padding: "10px",
                                                borderRadius: "5px",
                                                display: "flex",
                                                maxWidth: "650px",
                                                flexDirection: "column",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                position: "relative",
                                                overflowY: "auto",
                                                border: "1px solid #000",
                                                height: "100%",
                                                cursor: "pointer",
                                                transition: "transform 0.3s, background-color 0.3s",
                                            }}
                                            onMouseEnter={(e) => {
                                                // e.currentTarget.style.backgroundColor = "rgba(240, 240, 240, 0.5)";
                                                e.currentTarget.style.transform = "scale(1)";
                                            }}
                                            onMouseLeave={(e) => {
                                                e.currentTarget.style.backgroundColor = "";
                                                e.currentTarget.style.transform = "scale(1)";
                                            }}
                                        >
                                            <div style={{ flex: 1, textAlign: "center", marginTop: "10px" }}>
                                                <strong
                                                    style={{
                                                        fontSize: "0.9em",
                                                        display: "block",
                                                        marginBottom: "2px",
                                                    }}
                                                >
                                                    {source.title}
                                                </strong>
                                                <p style={{ fontSize: "0.8em", margin: "0", marginBottom: "15px" }}>
                                                    "{source.comments.split(' ').slice(0, 30).join(' ')}..."
                                                </p>
                                            </div>
                                            <iframe
                                                width="100%"
                                                height="315"
                                                src={getYoutubeEmbedUrl(source.url)}
                                                title={source.title}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            ></iframe>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Videoboard;
