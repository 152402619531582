import MaterialIcon from "material-icons-react";

function FollowupQueries({
  handleUserMessage,
  textColor,
  buttonTexts,
  backgroundColor,
  isMobile
}) {
  return (
    <>
      <div
        style={{
          display: "flex",
          marginBottom: "10px",
          marginTop: "10px",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
      </div>
      <div
        style={{
          display: "grid",
          width: isMobile ? "95%" : "70%",
          marginTop: "10px",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "10px",
          marginBottom: "30px",
        }}
      >
        {buttonTexts.map((button) => (
          <button
            key={button.text}
            style={{
              backgroundColor: backgroundColor,
              borderRadius: "10px",
              padding: "15px 20px",
              fontSize: "1em",
              color: textColor,
              border: `2px solid black`, // Changed to a lighter gray color
              cursor: "pointer",
              transition: "background-color 0.3s ease, color 0.3s ease",
              display: "flex",
              alignItems: "center",
              justifyContent: "center", // Align items to the left
            }}
            onClick={() => {
              handleUserMessage(button.text);
            }}
            onMouseOver={(e) => {
              e.target.style.backgroundColor = "#f0f0f0"; // Assuming this is the color of the product cards
            }}
            onMouseOut={(e) => {
              e.target.style.backgroundColor = backgroundColor;
            }}
          >
            {/* <MaterialIcon
              icon={button.icon}
              size={20}
              color={textColor}
              key={textColor}
              style={{ marginRight: "10%" }} // Set % distance between icon and text
            /> */}
            {button.text}
          </button>
        ))}
      </div>
    </>
  );
}

export default FollowupQueries;
