function RecommendationComponent({ item }) {
  return (
    <div
      style={{
        marginTop: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      {JSON.parse(item.metadata.product_thumbnail) && JSON.parse(item.metadata.product_thumbnail).length > 0 && (
        <img
          src={JSON.parse(item.metadata.product_thumbnail)[0]}
          alt={item.title}
          style={{
            maxWidth: "min(300px, 70%)",
            maxHeight: "150px",
            objectFit: "contain",
            alignSelf: "center",
          }}
        />
      )}
      <h1
        style={{
          fontSize: "1.5em",
          fontWeight: "bold",
          marginTop: "10px",
          textAlign: "center", // Centered text
        }}
      >
        ${parseFloat(item.metadata.price).toFixed(2)}
      </h1>
      <h2
        style={{
          fontSize: "1em",
          fontWeight: "bold",
          textTransform: "capitalize",
          marginBottom: "10px",
          textAlign: "center", // Centered text
        }}
      >
        {item.metadata.product_type}
      </h2>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {/* {Object.entries(item.metadata.specs)
          .slice(0, 4)
          .map(([key, value], index) => (
            <div key={key} style={{ flexBasis: "50%" }}>
              <strong style={{ fontSize: "1.1em" }}>
                {key
                  .replace(/_/g, " ")
                  .replace(/\b\w/g, (char) => char.toUpperCase())}
                :
              </strong>
              <p style={{ fontSize: "0.9em" }}>
              {Array.isArray(value) ? (value.join(', ').length > 20 ? value.join(', ').substring(0, 20) + '...' : value.join(', ')) : (value && value.length > 20 ? value.substring(0, 20) + '...' : value || "Unknown")}
              </p>
            </div>
          ))} */}
        <p
          style={{
            textAlign: "center",
            flexBasis: "100%",
            fontWeight: "bold",
            fontSize: "1.1em",
          }}
        >
          Click to see more...
        </p>
      </div>
    </div>
  );
}

export default RecommendationComponent;
