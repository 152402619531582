import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
function UpButton({ item, i, moveToTop, clickTimeoutId, setClickTimeoutId }) {
  return (
    <button
      style={{
        position: "absolute",
        top: "5px",
        right: "75px",
        background: i !== 0 ? "#008000" : "#808080",
        color: "white",
        border: "none",
        borderRadius: "3px",
        width: "30px",
        height: "30px",
        cursor: "pointer",
        padding: "5px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={(e) => {
        e.stopPropagation();
        if (clickTimeoutId !== null) {
          clearTimeout(clickTimeoutId);
          setClickTimeoutId(null);
        } else {
          const timeoutId = setTimeout(() => {
            if (i !== 0) {
              moveToTop(item.id, false);
            }
            setClickTimeoutId(null);
          }, 200); // delay in ms
          setClickTimeoutId(timeoutId);
        }
      }}
      onDoubleClick={(e) => {
        e.stopPropagation();
        if (clickTimeoutId !== null) {
          clearTimeout(clickTimeoutId);
          setClickTimeoutId(null);
        }
        if (i !== 0) {
          moveToTop(item.id, true);
        }
      }}
      disabled={i === 0}
    >
      <i className="material-icons" style={{ fontSize: "18px" }}>
        arrow_upward
      </i>
    </button>
  );
}

function DownButton({
  item,
  i,
  data,
  moveToBottom,
  clickTimeoutId,
  setClickTimeoutId,
}) {
  return (
    <button
      style={{
        position: "absolute",
        top: "5px",
        right: "40px",
        background: i !== data.length - 1 ? "#4169E1" : "#808080",
        color: "white",
        border: "none",
        borderRadius: "3px",
        width: "30px",
        height: "30px",
        cursor: "pointer",
        padding: "5px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={(e) => {
        e.stopPropagation();
        if (clickTimeoutId !== null) {
          clearTimeout(clickTimeoutId);
          setClickTimeoutId(null);
        } else {
          const timeoutId = setTimeout(() => {
            if (i !== data.length - 1) {
              moveToBottom(item.id, false);
            }
            setClickTimeoutId(null);
          }, 200); // delay in ms
          setClickTimeoutId(timeoutId);
        }
      }}
      onDoubleClick={(e) => {
        e.stopPropagation();
        if (clickTimeoutId !== null) {
          clearTimeout(clickTimeoutId);
          setClickTimeoutId(null);
        }
        if (i !== data.length - 1) {
          moveToBottom(item.id, true);
        }
      }}
      disabled={i === data.length - 1}
    >
      <i className="material-icons" style={{ fontSize: "18px" }}>
        arrow_downward
      </i>
    </button>
  );
}

function CloseButton({ item, removeData, buttonColor }) {
  return (
    <div
      style={{
        position: "absolute",
        top: "5px",
        right: "5px",
      }}
    >
      <IconButton
        aria-label="close"
        onClick={(e) => {
          e.stopPropagation();
          removeData(item.id);
        }}
      >
        <CloseIcon sx={{ color: "black" }} />
      </IconButton>
    </div>
    // <button
    //   style={{
    //     position: "absolute",
    //     top: "5px",
    //     right: "5px",
    //     background: buttonColor,
    //     color: "white",
    //     border: "none",
    //     borderRadius: "3px",
    //     width: "30px",
    //     height: "30px",
    //     cursor: "pointer",
    //     padding: "5px",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   }}
    //   onClick={(e) => {
    //     e.stopPropagation();
    //     removeData(item.id);
    //   }}
    // >
    //   <i
    //     className="material-icons"
    //     style={{ fontSize: "18px", cursor: "pointer", fontWeight: "bold" }}
    //   >
    //     close
    //   </i>
    // </button>
  );
}

export { CloseButton, DownButton, UpButton };
