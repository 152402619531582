function SocketReceiveChat(
  setChatMessages,
  newMessage,
  setWaitingForResponse,
  websocketRef
) {
  setChatMessages((prevMessages) => {
    const lastMessageIndex = prevMessages.length - 1;
    const lastMessage = prevMessages[lastMessageIndex];

    if (lastMessage && lastMessage.sender === "assistant") {
      // Create a new object for the last message to avoid direct mutation
      const updatedLastMessage = {
        ...lastMessage,
        message: lastMessage.message + newMessage.message,
      };
      // Return the new array with the updated last message
      return [...prevMessages.slice(0, lastMessageIndex), updatedLastMessage];
    } else {
      // Add a new message to the array
      return [
        ...prevMessages,
        { message: newMessage.message, sender: "assistant" },
      ];
    }
  });

  setWaitingForResponse(false);
}

function SocketReceiveRecommendations(
  setChatMessages,
  setSources,
  setData,
  setWaitingForResponse,
  websocketRef,
  response_json,
  topic
) {
  //console.log("SocketReceiveRecommendations response_json", response_json);
  setSources(
    Array.from(
      new Set(
        response_json.map((item) =>
          JSON.stringify({
            text: item.fields["source_name"],
            link: item.fields["source"],
          })
        )
      )
    ).map((item) => JSON.parse(item))
  );

  setWaitingForResponse(false);

  if (
    websocketRef.current &&
    websocketRef.current.readyState === WebSocket.OPEN
  ) {
    //console.log("sending data to websocket");
    websocketRef.current.send(
      JSON.stringify({
        type: "explain_suggestion",
      })
    );
    // for (let i = 0; i < model_data.length; i++) {
    //   SocketReceiveListings(websocketRef, model_data[i].title);
    // }
  } else {
    console.error("WebSocket is not open.");
  }

  const productNamesJson = response_json.map((item) =>
    JSON.stringify(item.fields)
  );
  setChatMessages((prevMessages) => [
    ...prevMessages,
    { message: productNamesJson, sender: "recommender", topic: topic },
  ]);
}

function SocketReceiveCriteria(
  setChatMessages,
  newMessage,
  setWaitingForResponse,
  setData,
  websocketRef
) {
  setChatMessages((prevMessages) => {
    const lastMessageIndex = prevMessages.length - 1;
    const lastMessage = prevMessages[lastMessageIndex];

    if (lastMessage.sender === "assistant") {
      // Create a new object for the last message to avoid direct mutation
      const combinedMessage = lastMessage.message + newMessage.message;
      let updatedMessage = combinedMessage.replace(/null/g, "");
      const updatedLastMessage = {
        ...lastMessage,
        message: updatedMessage,
      };
      //console.log(updatedMessage);
      // Return the new array with the updated last message
      return [...prevMessages.slice(0, lastMessageIndex), updatedLastMessage];
    } else {
      // Add a new message to the array
      return [
        ...prevMessages,
        {
          message: newMessage.message,
          sender: "assistant",
        },
      ];
    }
  });
  setWaitingForResponse(false);
}

function openCriteria(websocketRef, criterion) {
  if (
    websocketRef.current &&
    websocketRef.current.readyState === WebSocket.OPEN
  ) {
    //console.log("sending data to websocket");
    websocketRef.current.send(
      JSON.stringify({
        type: "display_criteria",
        requested_criteria: criterion,
      })
    );
  } else {
    console.error("WebSocket is not open.");
  }
}

function SocketReceiveListings(websocketRef, product_name) {
  if (
    websocketRef.current &&
    websocketRef.current.readyState === WebSocket.OPEN
  ) {
    //console.log("asking for listings for " + product_name);
    websocketRef.current.send(
      JSON.stringify({
        type: "get_listings",
        product_name: product_name,
      })
    );
  } else {
    console.error("WebSocket is not open.");
  }
}

function SocketSendStop(websocketRef) {
  if (
    websocketRef.current &&
    websocketRef.current.readyState === WebSocket.OPEN
  ) {
    websocketRef.current.send(JSON.stringify({ type: "stop" }));
  }
}

function SocketGetSourceSummaries(websocketRef, product_name, product_id) {
  if (
    websocketRef.current &&
    websocketRef.current.readyState === WebSocket.OPEN
  ) {
    //console.log("asking for source summaries for " + product_name);
    websocketRef.current.send(
      JSON.stringify({
        type: "get_source_summaries",
        product_name: product_name,
        product_id: product_id,
      })
    );
  } else {
    console.error("WebSocket is not open.");
  }
}

function SocketReceiveFollowups(
  setRelatedQueries,
  setWaitingForResponse,
  response_json
) {
  setRelatedQueries(response_json);
  setWaitingForResponse(false);
}


export {
  SocketReceiveChat,
  SocketReceiveRecommendations,
  SocketReceiveCriteria,
  openCriteria,
  SocketReceiveFollowups,
  SocketReceiveListings,
  SocketGetSourceSummaries,
  SocketSendStop,
};
