import React from "react";
import { Card, Typography, Link, CardContent } from "@mui/material";
import { Box } from "@mui/system";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";

const SourceCard = ({ source, textColor, backgroundColor }) => {
  // console.log("source", source);
  return (
    <Card
      sx={{
        width: 250,
        marginRight: 1,
        marginTop: 1,
        bgcolor: backgroundColor,
        height: 100,
      }}
    >
      <CardContent>
        <Typography
          variant="body2"
          color={textColor}
          sx={{ display: "block", marginBottom: 1 }}
          height={40}
        >
          {source.text.length > 50
            ? source.text.slice(0, 50) + "..."
            : source.text}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img
            src={`https://www.google.com/s2/favicons?domain=${source.link}`}
            alt="website logo"
            style={{ marginRight: 8 }}
          />
          <Link
            href={source.link}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: textColor,
              fontSize: "0.8em",
              fontWeight: "normal",
            }}
          >
            {new URL(source.link).hostname.length > 17
              ? new URL(source.link).hostname.slice(0, 17) + "..."
              : new URL(source.link).hostname}
          </Link>
        </Box>
      </CardContent>
    </Card>
  );
};

const Sources = ({
  textColor = "#000000",
  backgroundColor = "#1a1a1a",
  sources,
  isMobile = false,
}) => {
  if (sources.length === 0) {
    return null;
  }
  return (
    <div
      style={{
        padding: "0% 0%",
        width: "100%",
      }}
    >
      <div
        style={{
          padding: "0px",
          margin: "0px",
        }}
      >
        <h1
          style={{
            fontSize: isMobile ? "1.3em" : "1.5em",
            fontWeight: "bold",
            margin: 0,
            display: "flex",
            alignItems: "center",
          }}
        >
          <AutoAwesomeMotionIcon style={{ marginRight: "8px" }} />
          Sources
        </h1>
        <Box
          sx={{
            display: "flex",
            overflowX: "auto",

            width: "100%", // Ensure the Box takes full width
            "& > *": {
              minWidth: "240px", // Fixed minimum width for each SourceCard
              flexShrink: 0, // Prevent SourceCard from shrinking
            },
          }}
        >
          {sources.map((source, index) => (
            <SourceCard
              key={index}
              source={source}
              textColor={textColor}
              backgroundColor={backgroundColor}
            />
          ))}
        </Box>
      </div>
    </div>
  );
};

export default Sources;
